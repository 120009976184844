import { useEffect, useState } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { AppDispatch } from '../../../redux/store'
import { useClearBodyNoScrollClass } from '../../../hooks/use-clear-body-no-scroll-slass'
import { notificationsSelector } from '../../../redux/selectors/notification-selector'
import {
  fetchNotifications,
  setCurrentNotification,
  setVisibleDeleteModalNotification,
  setVisibleNotifications,
  setReadNotification,
} from '../../../redux/reducers/notifications'
import Spinner from '../../spinner/Spinner'
import styles from './NotificationModal.module.css'
import classNames from 'classnames'
import {
  fetchCar,
  setCurrentTab,
  setIsVisibleModalCar,
} from '../../../redux/reducers/cars'
import { formatDateAndTime, getLatestDate } from '../../../utils/date'

interface NotificationDataProps {
  description: string
  next_service_mileage: number
  related_id: number
  body: string
}

interface NotificationsProps {
  id: number
  type: string
  data: NotificationDataProps // Описываем массив объектов типа NotificationData
  read: boolean
  created_at: string
  updated_at: string
}

enum TypeNotification {
  NextServiceMileage = 'next_service_mileage',
  InsuranceRB = 'insurance_rb',
  InsuranceRF = 'insurance_rf',
  StateInspection = 'state_inspection',
  NewService = 'new_service',
  NewNote = 'new_note',
}

function NotificationsModal() {
  const dispatch = useDispatch<AppDispatch>()

  const { currentNotification } = useSelector(notificationsSelector)
  useClearBodyNoScrollClass()
  const { notifications = [], isPendingGetNotifications } = useSelector(
    notificationsSelector
  )

  useEffect(() => {
    dispatch(fetchNotifications())
  }, [dispatch])

  const handleDeleteNotification = (item: NotificationsProps) => {
    dispatch(setCurrentNotification(item))
    dispatch(setVisibleDeleteModalNotification(true))
  }

  const handleCloseClick = () => {
    dispatch(setVisibleNotifications(false))
  }

  const handleOpenLink = async (notification: NotificationsProps) => {
    if (
      notification.type === TypeNotification.NextServiceMileage ||
      notification.type === TypeNotification.NewService
    ) {
      await dispatch(fetchCar(notification.data.related_id)).unwrap()
      dispatch(setCurrentTab(2))
      dispatch(setIsVisibleModalCar(true))
      dispatch(setReadNotification(notification.id))
    } else if (
      notification.type === TypeNotification.InsuranceRB ||
      notification.type === TypeNotification.InsuranceRF ||
      notification.type === TypeNotification.StateInspection
    ) {
      await dispatch(fetchCar(notification.data.related_id)).unwrap()
      dispatch(setCurrentTab(1))
      dispatch(setIsVisibleModalCar(true))
      dispatch(setReadNotification(notification.id))
    } else if (notification.type === TypeNotification.NewNote) {
      await dispatch(fetchCar(notification.data.related_id)).unwrap()
      dispatch(setCurrentTab(3))
      dispatch(setIsVisibleModalCar(true))
      dispatch(setReadNotification(notification.id))
    }
  }

  return (
    <div className={classNames(styles.notificationPanel)}>
      <div className={classNames(styles.slidePanelTitle)}>
        <div className={classNames(styles.h4, 'mt-0 mb-0')}>Уведомления</div>
        <div
          className={classNames(styles.closeSlidePanel)}
          onClick={handleCloseClick}
        >
          ×
        </div>
      </div>
      <div className={styles.slidePanelContent}>
        {isPendingGetNotifications ? (
          <Spinner />
        ) : (
          notifications.map((item) => (
            <div
              key={item.id}
              className={classNames(
                'pt-3 pb-3 pl-3 pr-3 mb-3',
                styles.rowNotice,
                styles.bxShadowSmall,
                styles.bgGray,
                styles.borderRadius5
              )}
            >
              <div className={classNames(styles.small3, styles.colorGray)}>
                {formatDateAndTime(
                  getLatestDate(item.created_at, item.updated_at)
                )}
              </div>
              <div>
                <span
                  className={classNames(
                    item.read ? styles.normal : styles.bold,
                    styles.small3
                  )}
                  onClick={() => handleOpenLink(item)}
                >
                  {item.data.body}
                </span>
              </div>
              <div
                className={styles.hideNotice}
                onClick={() => handleDeleteNotification(item)}
              >
                ×
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  )
}

export default NotificationsModal
