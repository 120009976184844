import { useEffect, useState } from 'react'
import styles from './Header.module.css'
import { Link, NavLink, useLocation } from 'react-router-dom'
import { PATH } from '../../constants/path'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../../redux/store'
import { logout } from '../../redux/reducers/auth'
import { getRole } from '../../utils/getRole'
import classNames from 'classnames'
import { companySelector } from '../../redux/selectors/company-selector'
import { Icons } from 'react-toastify'
import {
  fetchNotifications,
  setVisibleNotifications,
} from '../../redux/reducers/notifications'
import { notificationsSelector } from '../../redux/selectors/notification-selector'

const LINKS = [
  {
    path: PATH.WAYBILLS,
    iconStyle: 'iconRouteWhite',
    title: 'Путевые листы',
    roles: ['isAdmin', 'isOwner'],
  },
  {
    path: PATH.DOCUMENTS,
    iconStyle: 'iconReportsWhite',
    title: 'Документы',
    roles: ['isAdmin', 'isOwner'],
  },
  {
    path: PATH.CLIENTS,
    iconStyle: 'iconReportsWhite',
    title: 'Клиенты',
    roles: ['isAdmin', 'isOwner'],
  },
  {
    path: PATH.REPORTS,
    iconStyle: 'iconReportsWhite',
    title: 'Отчёты',
    roles: ['isAdmin', 'isOwner'],
  },
  {
    path: PATH.MY_REPORTS,
    iconStyle: 'iconReportsWhite',
    title: 'Мои отчёты',
    roles: ['isDriver', 'isOwner', 'isAdmin'],
  },
  {
    path: PATH.EMPLOYEES,
    iconStyle: 'iconUsersWhite',
    title: 'Сотрудники',
    roles: ['isAdmin', 'isOwner'],
  },
  {
    path: PATH.CARS,
    iconStyle: 'iconTruckWhite',
    title: 'Транспорт',
    roles: ['isAdmin', 'isOwner'],
  },
  {
    path: PATH.PARTS,
    iconStyle: 'iconTruckWhite',
    title: 'Запчасти',
    roles: ['isAdmin', 'isOwner'],
  },
  {
    path: PATH.PROFILE,
    iconStyle: 'iconProfileWhite',
    title: 'Мой аккаунт',
    roles: ['isAdmin', 'isOwner', 'isDriver'],
  },
  {
    path: PATH.SETTINGS,
    iconStyle: 'iconProfileWhite',
    title: 'Настройки',
    roles: ['isAdmin', 'isOwner'],
  },
  // {
  //   path: PATH.SETTINGS,
  //   iconStyle: 'iconProfileWhite',
  //   title: 'Мой аккаунт',
  //   // roles: ['isAdmin', 'isOwner', 'isDriver'],
  // },
] as const

function Header() {
  const dispatch = useDispatch<AppDispatch>()
  const roles = getRole()
  const { currentCompany } = useSelector(companySelector)

  const { notifications = [], isPendingGetNotifications } = useSelector(
    notificationsSelector
  )
  const [unreadNotificationQuantity, setUnreadNotificationQuantity] =
    useState(10)
  const [isVisibleMobileMenu, setVisibleMobileMenu] = useState(false)
  const item = localStorage.getItem('userInfo')
  const userInfo = item ? JSON.parse(item) : {}
  const handleLogout = () => {
    localStorage.removeItem('userInfo')
    localStorage.removeItem('init')
    dispatch(logout())
    window.location.href = PATH.AUTH
  }
  const location = useLocation()

  let retrievedObject

  const itemInit = localStorage.getItem('init')
  if (itemInit) {
    retrievedObject = JSON.parse(itemInit)
  }

  useEffect(() => {
    dispatch(fetchNotifications())
  }, [])

  useEffect(() => {
    const unreadCount = notifications.filter(
      (notification) => !notification.read
    ).length
    setUnreadNotificationQuantity(unreadCount)
  }, [notifications])

  const handleOpenNotifications = () => {
    dispatch(setVisibleNotifications(true))
  }

  return (
    <div>
      {/* DESKTOP */}
      <div
        className={styles.headerAccount}
        style={{ left: isVisibleMobileMenu ? 0 : 'auto' }}
      >
        <div className={styles.avatar}>
          <img
            src={userInfo?.avatar?.sm || 'img/users/no-avatar.png'}
            className={styles.avatarImg}
          />

          <Link
            className={styles.avatarLink}
            to={PATH.PROFILE}
            onClick={() => setVisibleMobileMenu(false)}
          >
            {userInfo?.name}
          </Link>
          <div
            className={classNames(
              'd-none d-lg-inline-block',
              styles.btn,
              styles.btnBlueOutline,
              styles.btnSmall,
              styles.btnIcon,
              styles.notices
            )}
            onClick={handleOpenNotifications}
          >
            <i
              className={classNames(
                styles.iconBellLinear,
                styles.icon_20,
                styles.icon
              )}
            ></i>
            {unreadNotificationQuantity > 0 && (
              <div className={styles.badge}>
                <span className={styles.amount}>
                  {unreadNotificationQuantity}
                </span>
              </div>
            )}
          </div>

          <a onClick={handleLogout} className={styles.iconLogout}></a>
        </div>

        {/* MOBILE */}

        <div className={styles.accountMobilePanel}>
          <a href="" className={styles.logoMobile}>
            <img
              className={styles.avatarImgMobile}
              src="img/logo/logo-white.svg"
              alt=""
            />
          </a>

          <div
            className={classNames(
              styles.btnBlueOutline,
              styles.btnSmall,
              styles.btnMobile,
              'd-lg-inline-block'
            )}
            onClick={handleOpenNotifications}
          >
            <i
              className={classNames(
                styles.iconBellLinear,
                styles.icon20,
                styles.icon,
                styles.iconWhite
              )}
            ></i>
            {unreadNotificationQuantity > 0 && (
              <div className={styles.badgeMobile}>
                <span className={styles.amount}>
                  {unreadNotificationQuantity}
                </span>
              </div>
            )}
          </div>

          <div
            className={classNames(
              styles.menuMobile,
              isVisibleMobileMenu ? styles.opened : ''
            )}
            onClick={() => setVisibleMobileMenu(!isVisibleMobileMenu)}
          />
        </div>

        <div className={styles.accountMobileMenu}>
          {LINKS.filter((link) => link.roles.some((role) => roles[role])).map(
            ({ title, path }) => (
              <NavLink
                key={path}
                className={classNames(
                  styles.navLink,
                  location.pathname === path && styles.active
                )}
                to={path}
                onClick={() => setVisibleMobileMenu(false)}
              >
                <span>{title}</span>
              </NavLink>
            )
          )}
        </div>

        <div className="top-menu-account"></div>
      </div>
    </div>
  )
}

export default Header
