import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'

import styles from './NewServiceModal.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../../../redux/store'
import { setIsVisibleModalCar } from '../../../redux/reducers/cars'
import classNames from 'classnames'
import { PhotoProvider, PhotoView } from 'react-photo-view'
import {
  fetchAddService,
  setIsVisibleModalService,
} from '../../../redux/reducers/services'
import { useClearBodyNoScrollClass } from '../../../hooks/use-clear-body-no-scroll-slass'
import { toast } from 'react-toastify'
import { IMAGE_TYPE } from '../../../constants/file-types'
import LoadingPage from '../../loading/LoadingPage'
import { serviceSelector } from '../../../redux/selectors/service-selector'
import { onlyNumberRegularCheck } from '../../../utils/onlyNumberRegularCheck'

type FileArray = File[]

function NewServiceModal() {
  const dispatch = useDispatch<AppDispatch>()
  useClearBodyNoScrollClass()

  const [date, setDate] = useState('')
  const [mileage, setMileage] = useState('')
  const [description, setDescription] = useState('')
  const [nextServiceMileage, setNextServiceMileage] = useState('')
  const [coast, setCoast] = useState('')
  const [isDisabledSaveService, setIsDisabledSaveService] = useState(false)
  const { isPendingAddService } = useSelector(serviceSelector)
  const [currency, setCurrency] = useState('BYN')

  const textareaRef = useRef(null)

  const [selectedPhoto, setSelectedPhoto] = useState<string[]>([])
  const [selectedPhotoForSend, setSelectedPhotoForSend] = useState<File[]>([])

  const handlePhotoChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    if (event.target.files) {
      const files = event.target.files
      const maxSize = 50 * 1024 * 1024 // 50MB в байтах

      for (let i = 0; i < files.length; i++) {
        if (files[i].size > maxSize) {
          toast.error(
            `Файл ${files[i].name} превышает максимальный размер 50MB.`
          )
          return
        }
      }
      const filesArray: FileArray = Array.from(event.target.files)

      setSelectedPhotoForSend((prevPhotos: FileArray) => [
        ...prevPhotos,
        ...filesArray,
      ])

      const photosUrls: string[] = filesArray.map((file) =>
        URL.createObjectURL(file)
      )

      setSelectedPhoto((prevUrls: string[]) => [...prevUrls, ...photosUrls])
    }
  }

  const handleDeletePhoto = (photo: string) => {
    setSelectedPhoto((currentPhotos) =>
      currentPhotos.filter((e) => e !== photo)
    )

    const index = selectedPhoto.findIndex((e) => e === photo)

    setSelectedPhotoForSend((currentFiles) =>
      currentFiles.filter((_, i) => i !== index)
    )
  }

  useLayoutEffect(() => {
    if (textareaRef.current) {
      //@ts-ignore
      textareaRef.current.style.height = 'inherit'
      //@ts-ignore
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`
    }
  }, [description])

  const handleAddNewService = () => {
    dispatch(
      fetchAddService({
        date,
        mileage,
        description,
        photos: selectedPhotoForSend,
        nextServiceMileage,
        amount: coast,
        currency,
      })
    )
  }

  const handleCurrencyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setCurrency(value)
  }

  useEffect(() => {
    const isDateExist = date
    const isDescrExist = description

    const disabled = !isDateExist || !isDescrExist

    setIsDisabledSaveService(disabled)
  }, [date, description])

  return (
    <div style={{ display: 'block' }} className={styles.win}>
      <div className={styles.winContainer}>
        <div className={styles.winContent}>
          <div
            onClick={() => dispatch(setIsVisibleModalService(false))}
            className={styles.close}
          >
            ×
          </div>
          <div className={styles.winContentFulltext}>
            <h3
              style={{ textAlign: 'center' }}
              className={classNames(styles.h3)}
            >
              Сервисная запись
            </h3>
            <div
              className={classNames(
                'row',
                styles.small2,
                styles.borderRadius5,
                styles.rowNote,
                styles.bxShadow
              )}
            >
              <div className="col-6 col-sm-3 col-md-3 col-lg-3 col-xl-3 mb-3">
                <div className={classNames('mb-1', styles.small2)}>Дата *</div>
                <div
                  className={styles.calendar}
                  id="calendar_insurance_date_by"
                >
                  <input
                    // min={insuranceEndRb || undefined}
                    className={classNames(styles.calendarInput, styles.date)}
                    type="date"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                    pattern="\d{4}-\d{2}-\d{2}"
                  />
                </div>
              </div>
              <div className="col-6 col-sm-3 col-md-3 col-lg-3 col-xl-3 mb-3">
                <div className={classNames('mb-1', styles.small2)}>
                  Спидометр, км
                </div>
                <input
                  value={mileage}
                  onChange={(e) => {
                    if (onlyNumberRegularCheck(e)) {
                      setMileage(e.target.value)
                    }
                  }}
                  required
                  type="text"
                />
              </div>

              <div className="col-6 col-sm-3 col-md-3 col-lg-3 col-xl-3 mb-3">
                <div className={classNames('mb-1', styles.small2)}>
                  След. сервис,км
                </div>
                <input
                  className={styles.inputService}
                  value={nextServiceMileage}
                  onChange={(e) => {
                    if (onlyNumberRegularCheck(e)) {
                      setNextServiceMileage(e.target.value)
                    }
                  }}
                  required
                  type="text"
                  //   placeholder="VIN номер"
                />
              </div>

              <div className="col-6 col-sm-3 col-md-3 col-lg-3 col-xl-3 mb-3">
                <div className={classNames('mb-1', styles.small2)}>
                  Стоимость
                </div>
                <input
                  className={styles.inputService}
                  value={coast}
                  onChange={(e) => {
                    if (onlyNumberRegularCheck(e)) {
                      setCoast(e.target.value)
                    }
                  }}
                  required
                  type="text"
                  placeholder="Стоимость"
                />
                <div
                  className={classNames(
                    styles.radioTabs,
                    styles.small3,
                    'd-flex flex-wrap justify-content-start'
                  )}
                  style={{ textAlign: 'left' }}
                >
                  <div className="mt-1">
                    <input
                      name="currency"
                      id="currency_radio6"
                      type="radio"
                      value="BYN"
                      checked={currency === 'BYN'}
                      onChange={handleCurrencyChange}
                    />
                    <label
                      className={styles.labelCurrency}
                      htmlFor="currency_radio6"
                    >
                      BYN
                    </label>
                  </div>
                  <div className="mt-1">
                    <input
                      name="currency"
                      id="currency_radio4"
                      type="radio"
                      value="EUR"
                      checked={currency === 'EUR'}
                      onChange={handleCurrencyChange}
                    />
                    <label
                      className={styles.labelCurrency}
                      htmlFor="currency_radio4"
                    >
                      EUR
                    </label>
                  </div>
                  <div className="mt-1">
                    <input
                      name="currency"
                      id="currency_radio5"
                      type="radio"
                      value="RUB"
                      checked={currency === 'RUB'}
                      onChange={handleCurrencyChange}
                    />
                    <label
                      className={styles.labelCurrency}
                      htmlFor="currency_radio5"
                    >
                      RUB
                    </label>
                  </div>
                </div>
              </div>

              <div className="col-12 col-sm-9 col-md-9 col-lg-9 col-xl-9 mb-3">
                <div className={classNames('mb-1', styles.small2)}>
                  Что сделано *
                </div>
                <textarea
                  className={styles.noteText}
                  ref={textareaRef}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>

              <div className="col-5 col-sm-3 col-md-3 col-lg-3 col-xl-3 mb-3 align-content-end">
                <div
                  className={classNames(
                    styles.btn,
                    styles.btnSmall,
                    styles.btnBlueOutline,
                    styles.loadGallery
                  )}
                >
                  <i
                    className={classNames(
                      styles.icon,
                      styles.icon15,
                      styles.iconPicture
                    )}
                  ></i>
                  + Фото
                  <input
                    multiple={true}
                    onChange={handlePhotoChange}
                    type="file"
                    accept={IMAGE_TYPE}
                    name="report_gallery"
                    id="report_gallery"
                  />
                </div>
              </div>

              <div className={classNames('col-12', styles.photoContainer)}>
                <PhotoProvider className={styles.photoProvider}>
                  {selectedPhoto?.map((photo) => (
                    <PhotoView src={photo}>
                      <div className={styles.photoView}>
                        <img src={photo} alt="" />
                        <span
                          onClick={(e) => {
                            e.stopPropagation()
                            handleDeletePhoto(photo)
                          }}
                          className={styles.del}
                        >
                          ×
                        </span>
                      </div>
                    </PhotoView>
                  ))}
                </PhotoProvider>
              </div>
            </div>
            <div className="row mt-3">
              <div
                className="col-6 col-sm-6 mt-1 mb-1"
                style={{ textAlign: 'left' }}
              >
                <button
                  className={classNames(
                    'd-block',
                    'd-sm-inline-block',
                    styles.btn,
                    styles.btnSmall
                  )}
                  onClick={handleAddNewService}
                  disabled={isDisabledSaveService}
                >
                  <i className={`${styles.icon} ${styles.iconCheckWhite}`} />{' '}
                  Сохранить
                </button>
              </div>
              <div
                className="col-6 col-sm-6 mt-1 mb-1"
                style={{ textAlign: 'right' }}
              >
                <div
                  onClick={() => dispatch(setIsVisibleModalService(false))}
                  className={classNames(
                    'd-block',
                    'd-sm-inline-block',
                    styles.btn,
                    styles.btnSmall,
                    styles.btnBlueOutline
                  )}
                >
                  Отмена
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewServiceModal
