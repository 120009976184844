import {
  addDays,
  addMonths,
  endOfDay,
  endOfMonth,
  endOfWeek,
  endOfYear,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from 'date-fns'

export const DEFINEDS = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
  startOf2023: startOfYear(new Date(2023, 0, 1)),
  endOf2023: endOfYear(new Date(2023, 0, 1)),
  startOf2022: startOfYear(new Date(2022, 0, 1)),
  endOf2022: endOfYear(new Date(2022, 0, 1)),
  startOf2021: startOfYear(new Date(2021, 0, 1)),
  endOf2021: endOfYear(new Date(2021, 0, 1)),
  startOf2024: startOfYear(new Date(2024, 0, 1)),
  endOf2024: endOfYear(new Date(2024, 0, 1)),
}

export const staticRangesArray = [
  {
    label: 'Вчера',
    isSelected: () => true,
    range: () => ({
      startDate: DEFINEDS.startOfYesterday,
      endDate: DEFINEDS.endOfYesterday,
    }),
  },
  {
    label: 'Этот месяц',
    isSelected: () => true,
    range: () => ({
      startDate: DEFINEDS.startOfMonth,
      endDate: DEFINEDS.endOfMonth,
    }),
  },
  {
    label: 'Прошлый месяц',
    isSelected: () => true,
    range: () => ({
      startDate: DEFINEDS.startOfLastMonth,
      endDate: DEFINEDS.endOfLastMonth,
    }),
  },
  {
    label: '2024',
    isSelected: () => true,
    range: () => ({
      startDate: DEFINEDS.startOf2024,
      endDate: DEFINEDS.endOf2024,
    }),
  },
  {
    label: '2023',
    isSelected: () => true,
    range: () => ({
      startDate: DEFINEDS.startOf2023,
      endDate: DEFINEDS.endOf2023,
    }),
  },
  {
    label: '2022',
    isSelected: () => true,
    range: () => ({
      startDate: DEFINEDS.startOf2022,
      endDate: DEFINEDS.endOf2022,
    }),
  },
  {
    label: '2021',
    isSelected: () => true,
    range: () => ({
      startDate: DEFINEDS.startOf2021,
      endDate: DEFINEDS.endOf2021,
    }),
  },
]
