/* eslint-disable jsx-a11y/anchor-is-valid */
import classNames from 'classnames'
import styles from './ReportsResult.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { reportsSelector } from '../../../redux/selectors/reports-selector'
import { AppDispatch } from '../../../redux/store'
import {
  fetchReportFile,
  Report,
  setCurrentReportId,
  setIsVisibleDeleteModalReport,
  setIsVisibleModalReport,
} from '../../../redux/reducers/reports'
import Spinner from '../../../components/spinner/Spinner'
import EditButton from '../../../components/buttons/actions-button/EditButton'
import DeleteButton from '../../../components/buttons/actions-button/DeleteButton'
import { setIsVisibleModalCar } from '../../../redux/reducers/cars'
import { getHost } from '../../../utils/getHost'
import { URL as URL_APP } from '../../../constants/url'

const BUTTON_INFO = [
  {
    color: 'bgRed',
    title: 'Расходы',
    count: '',
  },
  {
    color: 'bgBlue',
    title: 'Расстояние',
    count: '',
  },
  {
    color: 'bgLightBlue',
    title: 'Машин',
    count: '',
  },
  {
    color: 'bgTurquoise',
    title: 'Людей',
    count: '',
  },
]

function ReportsResult({ dateFrom, dateTo, getEmployees, getCars }: any) {
  const dispatch = useDispatch<AppDispatch>()
  const [sortField, setSortField] = useState<number | string | null>(null)
  const [sortOrder, setSortOrder] = useState('asc')
  const [buttonInfo, setButtonInfo] = useState(BUTTON_INFO)
  const { reports, isPendingGetReports } = useSelector(reportsSelector)
  const [sortNewReports, setSortNewReports] = useState<any>([])

  const sortReports = (field: number | string) => {
    const order = sortField === field && sortOrder === 'asc' ? 'desc' : 'asc'
    setSortField(field)
    setSortOrder(order)

    if (field === 'Truck') {
      const sortedReports = [...reports].sort((a, b) => {
        //@ts-ignore
        if (a.car.name < b.car.name) {
          return order === 'asc' ? -1 : 1
        }
        //@ts-ignore

        if (a.car.name > b.car.name) {
          return order === 'asc' ? 1 : -1
        }
        return 0
      })
      setSortNewReports(sortedReports)
    }

    if (field === 'Employees') {
      const sortedReports = [...reports].sort((a, b) => {
        //@ts-ignore
        if (a.user.name < b.user.name) {
          return order === 'asc' ? -1 : 1
        }
        //@ts-ignore

        if (a.user.name > b.user.name) {
          return order === 'asc' ? 1 : -1
        }
        return 0
      })
      setSortNewReports(sortedReports)
    }

    if (field === 'Date') {
      const sortedReports = [...reports].sort((a, b) => {
        //@ts-ignore
        if (a.date_from < b.date_from) {
          return order === 'asc' ? -1 : 1
        }
        //@ts-ignore

        if (a.date_from > b.date_from) {
          return order === 'asc' ? 1 : -1
        }
        return 0
      })
      setSortNewReports(sortedReports)
    }

    if (field === 'Route') {
      const sortedReports = [...reports].sort((a, b) => {
        //@ts-ignore
        if (a.distance < b.distance) {
          return order === 'asc' ? -1 : 1
        }
        //@ts-ignore

        if (a.distance > b.distance) {
          return order === 'asc' ? 1 : -1
        }
        return 0
      })
      setSortNewReports(sortedReports)
    }

    if (field === 'Expenses') {
      const sortedReports = [...reports].sort((a, b) => {
        //@ts-ignore
        if (a.cost < b.cost) {
          return order === 'asc' ? -1 : 1
        }
        //@ts-ignore

        if (a.cost > b.cost) {
          return order === 'asc' ? 1 : -1
        }
        return 0
      })
      setSortNewReports(sortedReports)
    }

    // if (field === 'Time') {
    //   const sortedReports = [...reports].sort((a, b) => {
    //     const dateAFrom = new Date(a.date_from)
    //     const dateATo = new Date(a.date_to)

    //     const dateBFrom = new Date(b.date_from)
    //     const dateBTo = new Date(b.date_to)

    //     //@ts-ignore
    //     const differenceInMillisecondsA = dateATo - dateAFrom
    //     //@ts-ignore
    //     const differenceInMillisecondsB = dateBTo - dateBFrom

    //     //@ts-ignore
    //     if (differenceInMillisecondsA < differenceInMillisecondsB) {
    //       return order === 'asc' ? -1 : 1
    //     }
    //     //@ts-ignore

    //     if (differenceInMillisecondsA > differenceInMillisecondsB) {
    //       return order === 'asc' ? 1 : -1
    //     }
    //     return 0
    //   })
    //   setSortNewReports(sortedReports)
    // }
  }

  useEffect(() => {
    setSortNewReports(reports)
  }, [reports])

  useEffect(() => {
    const sumDistance = reports.reduce((totalMileage, report) => {
      //@ts-ignore
      const mileageBefore = report.mileage_before || 0
      //@ts-ignore

      const mileageAfter = report.mileage_after || 0

      const mileageDifference = mileageAfter - mileageBefore

      return totalMileage + (!!mileageAfter ? mileageDifference : 0)
    }, 0)

    //@ts-ignore
    const sumCost = reports?.reduce((acc, report) => {
      return acc + Number(report.all_expenses || 0)
    }, 0)

    const filteredCars = reports
      .map((item) => item.car)
      .filter((car) => car !== null)
    //@ts-ignore
    const uniqueCarIds = [...new Set(filteredCars.map((car) => car.id))]

    const filteredUsers = reports
      .map((item) => item.user)
      .filter((user) => user !== null)
    //@ts-ignore
    const uniqueUsersIds = [...new Set(filteredUsers.map((user) => user.id))]

    setButtonInfo(
      buttonInfo.map((info) => {
        if (info.title === 'Расходы') {
          return {
            ...info,
            count: `${sumCost?.toLocaleString('en', { minimumFractionDigits: 0, maximumFractionDigits: 0 })} р`,
          }
        } else if (info.title === 'Расстояние') {
          return {
            ...info,
            count: `${sumDistance?.toLocaleString('en', { minimumFractionDigits: 0, maximumFractionDigits: 0 })} км`,
          }
        } else if (info.title === 'Машин') {
          return { ...info, count: `${uniqueCarIds.length}` }
        } else if (info.title === 'Людей') {
          return { ...info, count: `${uniqueUsersIds.length}` }
        }
        return info
      })
    )
  }, [reports])

  const handleEditReport = (item: any) => {
    dispatch(setCurrentReportId(item.id))
    dispatch(setIsVisibleModalReport(true))
  }

  const handleDeleteReport = (item: any) => {
    dispatch(setCurrentReportId(item.id))
    dispatch(setIsVisibleDeleteModalReport(true))
  }

  const [currentPage, setCurrentPage] = useState(1)
  const reportsPerPage = 50

  const pageCount = Math.ceil(sortNewReports.length / reportsPerPage)

  const indexOfLastReport = currentPage * reportsPerPage
  const indexOfFirstReport = indexOfLastReport - reportsPerPage
  const currentReports = sortNewReports.slice(
    indexOfFirstReport,
    indexOfLastReport
  )

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber)

  const selectedLoanWithoutAccount = false
  const getStatus = (status: any) => {
    switch (status) {
      case 'draft':
        return 'Черновик'
      case 'sent':
        return 'Отправлен'
      case 'closed':
        return 'Закрыт'
      default:
        return ''
    }
  }

  return (
    <>
      {' '}
      {isPendingGetReports ? (
        <Spinner />
      ) : (
        <>
          {' '}
          <div
            className={classNames(
              'row align-items-center mb-3',
              styles.information
            )}
          >
            <div className="col-5 col-sm-6">
              <h4 className={classNames(styles.h4, styles.reports, 'mb-0')}>
                Отчётов ({sortNewReports.length})
              </h4>
            </div>
            {/* <div className="col-7 col-sm-6" style={{ textAlign: 'right' }}>
              <span
                // onClick={() =>
                //   dispatch(
                //     fetchReportFile({
                //       dateFrom: new Date(dateFrom).toISOString().split('T')[0],
                //       dateTo: new Date(dateTo).toISOString().split('T')[0],
                //       exportFormat: 'pdf',
                //       getEmployees,
                //       getCars,
                //     })
                //   )
                // }
                className={classNames(
                  styles.btn,
                  styles.btnSmall2,
                  styles.btnTrans
                )}
              >
                <i className={classNames(styles.icon, styles.iconDownload)} />
                PDF
              </span>
              <a
                onClick={() =>
                  dispatch(
                    fetchReportFileByType({
                      dateFrom: new Date(dateFrom).toISOString().split('T')[0],
                      dateTo: new Date(dateTo).toISOString().split('T')[0],
                      exportFormat: 'xlsx',
                      getEmployees,
                      getCars,
                    })
                  )
                }
                download
                className={classNames(
                  styles.btn,
                  styles.btnSmall2,
                  styles.btnTrans
                )}
              >
                <i className={classNames(styles.icon, styles.iconDownload)} />
                XLS
              </a>
            </div> */}
          </div>
          <div className={classNames('row', styles.totalAmountPanel)}>
            {buttonInfo.map(({ color, title, count }) => (
              <div key={title} className=" col-6 col-sm-3 col-md-3 col-lg-3">
                <div className={classNames(styles.colCont, styles[color])}>
                  <p className={styles.small2}>{title}</p>
                  <b className={styles.fontBig}>{count}</b>
                </div>
              </div>
            ))}
          </div>
          {!!sortNewReports.length ? (
            <div
            // className={styles.tableBigWrapper}
            >
              <div
                className={classNames(
                  styles.docItem,
                  styles.small2,
                  'pl-2 pr-2 mt-4 mb-2'
                )}
              >
                <div className={classNames(styles.row, 'row')}>
                  <div
                    onClick={() => sortReports('item')}
                    className="d-none d-xl-block col-auto col-xl-2"
                  >
                    <span
                      className={classNames(
                        styles.colorGray,
                        styles.tableSortCol,
                        styles.sortDown
                      )}
                    >
                      Выезд
                    </span>
                  </div>
                  <div
                    onClick={() => sortReports('item')}
                    className="d-none d-xl-block col-xl-4"
                  >
                    <span className="color-gray">Машина/Водитель</span>
                  </div>
                  <div
                    onClick={() => sortReports('item')}
                    className="doc-money col-auto col-xl-auto pr-2 d-none d-xl-block"
                  >
                    <span className="color-gray table-sort-col">Расходы</span>
                  </div>
                  <div
                    onClick={() => sortReports('item')}
                    className="doc-km col-auto col-xl-auto"
                  >
                    <span className="color-gray table-sort-col d-none d-xl-block">
                      Дистанция
                    </span>
                  </div>
                </div>
              </div>
              {currentReports.map((item: Report) => {
                const getDate = (isoDateString: string) => {
                  const date = new Date(isoDateString)

                  const day = date.getDate().toString().padStart(2, '0')
                  const month = (date.getMonth() + 1)
                    .toString()
                    .padStart(2, '0')
                  const year = date.getFullYear()

                  const formattedDate = `${day}.${month}.${year}`
                  return formattedDate
                }

                const getTime = (date: string) => {
                  const newDate = new Date(date)
                  const hours = newDate.getHours().toString().padStart(2, '0')
                  const minutes = newDate
                    .getMinutes()
                    .toString()
                    .padStart(2, '0')

                  return `${hours}:${minutes}`
                }

                // const date1 = new Date(item.date_from)
                // const date2 = new Date(item.date_to)

                // //@ts-ignore
                // const differenceInMilliseconds = date2 - date1
                // const differenceInHours = Math.floor(
                //   differenceInMilliseconds / (1000 * 60 * 60)
                // )
                // const differenceInMinutes = Math.round(
                //   (differenceInMilliseconds % (1000 * 60 * 60)) / (1000 * 60)
                // )
                return (
                  <>
                    <div
                      className={classNames(
                        styles.bgWhite,
                        'pl-2 pr-2 mb-3',
                        styles.border10,
                        styles.bxShadow,
                        styles.small2,
                        styles.docItem
                      )}
                    >
                      <div className={classNames(styles.row, 'row')}>
                        <div
                          className={classNames(
                            styles.docStatus,
                            'col-auto col-sm-2 col-md-3 col-lg-3 col-xl-2 small-3 pr-2 pt-1 pb-1'
                          )}
                        >
                          <span
                            className={classNames(
                              'd-inline-block pl-2 pr-2 pt-0 pb-0 mb-1',
                              styles.borderRadius5,
                              item.status === 'draft' ? styles.bgRed : '',
                              item.status === 'sent' ? styles.bgTurquoise : '',
                              item.status === 'closed' ? styles.bgDarkBlue : ''
                            )}
                          >
                            {getStatus(item.status)}
                          </span>
                          <br />
                          <b>{getDate(item.departure)}</b>
                          <br />
                          {getTime(item.departure)}
                        </div>
                        <div
                          className={classNames(
                            styles.docCar,
                            'col-8 col-sm-6 col-md-5 col-lg-5 col-xl-4 pr-2 pt-1 pb-1'
                          )}
                        >
                          <a
                            onClick={() => handleEditReport(item)}
                            className={classNames(
                              styles.avatarLink,
                              'd-flex align-items-center mb-1'
                            )}
                          >
                            <span className={styles.name}>
                              {item.user.name}
                            </span>
                          </a>
                          <a
                            onClick={() => {
                              dispatch(setIsVisibleModalCar(item?.car?.id))
                            }}
                            className="d-block"
                          >
                            {item?.car?.name}
                          </a>
                          {item?.trailer?.name && (
                            <div
                              onClick={() => {
                                dispatch(
                                  setIsVisibleModalCar(item?.trailer?.id)
                                )
                              }}
                              className="mt-0"
                            >
                              Прицеп: <a>{item?.trailer?.name}</a>
                            </div>
                          )}
                        </div>

                        <div
                          className={classNames(
                            styles.docMoney,
                            'col-auto col-sm-2 col-md-3 col-lg-3 col-xl-auto pt-1 pb-1'
                          )}
                        >
                          <div
                            className={classNames(
                              styles.small3,
                              styles.colorLightGray,
                              'd-block d-xl-none'
                            )}
                          >
                            Расходы
                          </div>
                          <a>
                            <b
                              className={classNames(
                                styles.colorRed,
                                styles.nowrap
                              )}
                            >
                              {item.all_expenses.toLocaleString('en', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                              })}{' '}
                              р.
                            </b>
                          </a>
                        </div>
                        <div
                          className={classNames(
                            styles.docKm,
                            'col-auto col-sm-2 col-md-5 col-lg-5 col-xl-auto pt-1 pb-1'
                          )}
                        >
                          <div
                            className={classNames(
                              styles.small3,
                              styles.colorLightGray,
                              'd-block d-xl-none'
                            )}
                          >
                            Дистанция
                          </div>
                          <span className={styles.nowrap}>
                            {item.mileage_after
                              ? `${(item.mileage_after - item.mileage_before).toLocaleString('en', { minimumFractionDigits: 0, maximumFractionDigits: 0 })} км`
                              : 'В пути'}
                          </span>
                        </div>
                        <div
                          className="doc-buttons col pt-1 pb-1"
                          style={{ textAlign: 'right' }}
                        >
                          {item?.report_pdf_url ? (
                            <a
                              href={`${getHost()}${item?.report_pdf_url}`}
                              target="_blank"
                              data-tooltip="Скачать отчёт"
                              className={classNames(
                                styles.btn,
                                styles.btnBlueOutline,
                                styles.btnSmall2,
                                styles.btnIcon,
                                styles.tooltip,
                                'tooltip-left-center'
                              )}
                            >
                              <div className={classNames(styles.tooltipText)}>
                                Скачать отчёт
                              </div>
                              <i
                                className={classNames(
                                  styles.icon,
                                  styles.iconDownload
                                )}
                              ></i>
                            </a>
                          ) : null}

                          <EditButton onClick={() => handleEditReport(item)} />
                          <DeleteButton
                            onClick={() => handleDeleteReport(item)}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )
              })}
            </div>
          ) : (
            <div
              style={{ textAlign: 'center' }}
              className={classNames(styles.fontBig, styles.colorGray, 'mb-5')}
            >
              {!selectedLoanWithoutAccount && false}
            </div>
          )}
          <div style={{ textAlign: 'center' }}>
            {Array.from({ length: pageCount }, (_, index) => (
              <span
                className={classNames(
                  styles.pagingBtn,
                  styles.btn,
                  styles.btnSmall,
                  styles.btnBlueOutline,
                  currentPage === index + 1 && styles.activeButton
                )}
                key={index + 1}
                onClick={() => paginate(index + 1)}
              >
                {index + 1}
              </span>
            ))}
          </div>
        </>
      )}
    </>
  )
}

export default ReportsResult
