import React, { useState, useRef, useEffect } from 'react'
import classNames from 'classnames'
import styles from './ListDropDown.module.css'

export const ListDropdown = ({
  data = [],
  checkedItems,
  checkedIds = [],
  allItemsName,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [listData, setListData] = useState([])
  const [nameList, setNameList] = useState('')
  const [idList, setIdList] = useState([])
  const buttonRef = useRef(null)
  const dropdownRef = useRef(null)

  useEffect(() => {
    const carList = convertArrayForChecking(data, checkedIds)
    setListData(carList)
  }, [data, checkedIds])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsDropdownOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    const fetchNameList = () => {
      if (listData.length === 0) {
        setNameList('')
        setIdList([])
        return
      }
      const checkedItems = listData.filter((item) => item.isChecked)
      const checkedItemsWithoutFirst = checkedItems.filter(
        (item) => item.id !== -2
      )
      let buttonSign
      let ids
      if (checkedItemsWithoutFirst.length === 0) {
        const allItems = listData.find((item) => item.id === -2)
        buttonSign = allItems ? allItems.name : ''
        ids = allItems ? [allItems.id] : []
      } else {
        buttonSign = checkedItemsWithoutFirst
          .map((item) => item.name)
          .join(', ')
        ids = checkedItemsWithoutFirst.map((item) => item.id)
      }
      setNameList(buttonSign)
      setIdList(ids)
    }
    fetchNameList()
  }, [listData])

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev)
  }

  const toggleCheckbox = (item) => {
    let updatedList
    if (item.id === -1) {
      updatedList = listData.map((i) => ({
        ...i,
        isChecked: i.id === -1,
      }))
    } else {
      updatedList = listData.map((i) => {
        if (i.id === item.id) {
          return { ...i, isChecked: !i.isChecked }
        }
        return i
      })

      const hasCheckedItems = updatedList.some(
        (i) => i.isChecked && i.id !== -1
      )

      updatedList = updatedList.map((i) => {
        if (i.id === -1) {
          return { ...i, isChecked: !hasCheckedItems }
        }
        return i
      })
    }
    setListData(updatedList)
  }

  const handleClickDoneButton = () => {
    setIsDropdownOpen(false)
    checkedItems(
      listData.filter((item) => item.isChecked).map((item) => item.id)
    )
  }

  function convertArrayForChecking(data, checkedIds) {
    let newData
    const isAllChecked = checkedIds[0] === -1
    if (checkedIds && checkedIds.length > 0) {
      newData = [
        {
          id: -1,
          name: allItemsName,
          isChecked: isAllChecked,
          active: false,
          number: '',
          vin: '',
          mileage: 0,
          insurance_end_rb: '',
          insurance_end_rf: '',
          state_inspection_end: '',
          cargo_volume: 0,
          cargo_weight: 0,
          image: [],
        },
        ...data.map((item) => ({
          ...item,
          isChecked: checkedIds.includes(item.id),
        })),
      ]
    } else {
      newData = [
        {
          id: -1,
          name: allItemsName,
          isChecked: true,
          active: false,
          number: '',
          vin: '',
          mileage: 0,
          insurance_end_rb: '',
          insurance_end_rf: '',
          state_inspection_end: '',
          cargo_volume: 0,
          cargo_weight: 0,
          image: [],
        },
        ...data.map((item) => ({
          ...item,
          isChecked: false,
        })),
      ]
    }

    return newData
  }

  return (
    <div>
      <div className={styles.dropdown_container} ref={dropdownRef}>
        <button
          type="button"
          ref={buttonRef}
          onClick={toggleDropdown}
          className={classNames(styles.dropdown_button, styles.btn_input, {
            [styles.active]: isDropdownOpen,
          })}
        >
          {' '}
          {nameList}
        </button>
        {isDropdownOpen && (
          <div className={styles.dropdown_menu}>
            <div className={styles.dropdown_content}>
              {listData.map((item) => (
                <div className={styles.dropdown_item} key={item.id}>
                  <input
                    type="checkbox"
                    onChange={() => toggleCheckbox(item)}
                    checked={item.isChecked}
                  />
                  <label
                    className={classNames('hidden peer', styles.body)}
                    onClick={() => {
                      toggleCheckbox(item)
                    }}
                  >
                    {item.name}
                  </label>
                </div>
              ))}
            </div>
            <button
              className={styles.btnBlueOutline}
              onClick={handleClickDoneButton}
            >
              Применить
            </button>
          </div>
        )}
      </div>
    </div>
  )
}
