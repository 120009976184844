import { MouseEventHandler } from 'react'
import classNames from 'classnames'
import styles from './Actions.module.css'

interface DeleteButtonProps {
  onClick: MouseEventHandler<HTMLDivElement>
}

const DeleteButton = ({ onClick }: DeleteButtonProps) => (
  <div
    onClick={onClick}
    className={classNames(
      styles.btn,
      styles.btnSmall,
      styles.btnIcon,
      styles.btnOutline
    )}
  >
    <i className={classNames(styles.icon, styles.iconDeleteRed)} />
  </div>
)

export default DeleteButton
