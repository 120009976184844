import { useState } from 'react'
import { Link } from 'react-router-dom'
import { PATH } from '../../constants/path'

import styles from './ResetPassword.module.css'
import { AppDispatch } from '../../redux/store'
import { useDispatch } from 'react-redux'
import { fetchForgotPassword } from '../../redux/reducers/auth'
import Footer from '../../components/footer/Footer'

function ResetPassword() {
  const dispatch = useDispatch<AppDispatch>()

  const [email, setEmail] = useState('')
  let retrievedObject
  const item = localStorage.getItem('init')
  if (item) {
    retrievedObject = JSON.parse(item)
    // Use retrievedObject here
  }

  return (
    <div
      className={`${styles.block} ${styles.block1} ${styles.bgMonoh} ${styles.bgBlue}`}
      style={{ backgroundImage: 'url(img/logo/slide09.jpg)' }}
    >
      <div className={`container ${styles.container}`}>
        <div className="row">
          <div className="col-12 offset-sm-2 col-sm-8 offset-md-2 col-md-8 offset-lg-3 col-lg-6">
            <div className="mb-5" style={{ textAlign: 'center' }}>
              <a href="login.php" className={`${styles.logo} d-block mb-2`}>
                <img width="240" src="img/logo/logo-white.svg" alt="logo" />
              </a>
              <div className="mb-0">
                <b className={styles.b}>Система управления логистикой</b>
              </div>
            </div>

            <div
              className={`${styles.blockWhite} ${styles.borderRadius20}`}
              style={{ textAlign: 'left' }}
            >
              <h1
                className={`${styles.h2} ${styles.wow} ${styles.fadeIn}`}
                style={{ textAlign: 'center' }}
              >
                Восстановление пароля
              </h1>
              <form onSubmit={(e) => e.preventDefault()} className="mb-5">
                <input type="hidden" name="action" value="passrec" />
                <div className={styles.formLine}>
                  <small>E-mail / Login</small>
                  <input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    type="email"
                    name="email"
                    placeholder="Введите ваш E-mail или Login"
                  />
                </div>
                <p className={styles.small}>
                  Мы отправим вам новый пароль на вашу электронную почту.
                </p>
                <div
                  onClick={() => dispatch(fetchForgotPassword(email))}
                  style={{ textAlign: 'center' }}
                >
                  <input
                    type="submit"
                    className={`${styles.btn} d-block d-sm-inline-block`}
                    value="Отправить"
                  />
                </div>
              </form>
            </div>

            <div
              style={{ textAlign: 'center' }}
              className={`${styles.small} mt-5`}
            >
              <Link className={styles.colorWhite} to={PATH.AUTH}>
                <b>Вернуться к Входу</b>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default ResetPassword
