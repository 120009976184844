import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit'
import axiosInstance from '../../utils/axiosInstance'
import { AxiosResponse } from 'axios'
import { toast } from 'react-toastify'
import { RootState } from '../store'
import { URL } from '../../constants/url'
import { MyKnownError } from '../types/common'
import { Phone } from './phones'

interface Country {
  iso: string
  name: string
}

interface City {
  id: number | string
  name: string
}

interface Requisites {
  inn: string
  bank_bik: string
  bank_swift: string
  bank_account: string
  bank_name: string
  address: string
}

export interface Client {
  id: number
  active: boolean
  country: Country
  city: City
  district: string
  address: string
  entity: boolean
  entity_name: string
  name: string
  email: string
  phones: Phone[]
  requisites: Requisites
  logo: {
    lg: string
    md: string
    original: string
    sm: string
  }
  contracts: {
    active: boolean
    amount: string
    currency: string
    date: string
    expired: boolean
    expires_at: string
    id: number
    number: string
  }[]
}

export interface ClientState {
  currentClient: Client | null
  clients: Array<Client>
  isVisibleModalClient: boolean
  isVisibleDeleteModalClient: boolean
  isPendingGetClient: boolean
  currentClientByRequest: Client | null
  // РЕДАКТИРОВАНИЕ и ДОБАВЛЕНИЕ
  isPendingAddClient: boolean
  isFulfilledAddClient: boolean
  isRejectAddClient: boolean
}

const initialState: ClientState = {
  currentClient: null,
  clients: [],
  isVisibleModalClient: false,
  isVisibleDeleteModalClient: false,
  isPendingGetClient: false,
  currentClientByRequest: null,
  // РЕДАКТИРОВАНИЕ и ДОБАВЛЕНИЕ
  isPendingAddClient: false,
  isFulfilledAddClient: false,
  isRejectAddClient: false,
}

// Создание асинхронного действия для получения клиентов
export const fetchClients = createAsyncThunk(
  'clients/fetchClients',
  async (_, thunkAPI) => {
    try {
      const response: AxiosResponse<Client[]> = await axiosInstance.get(
        `${URL.clients}`
      )

      if (response.statusText === 'OK') {
        return response.data
      }
      throw new Error()
    } catch (error) {
      const typedError = error as MyKnownError

      return thunkAPI.rejectWithValue(
        typedError?.response?.data?.message || 'Ошибка при отправке данных'
      )
    }
  }
)

export const fetchClient = createAsyncThunk(
  'clients/fetchClient',
  async (id: string | number, thunkAPI) => {
    try {
      const response: AxiosResponse = await axiosInstance.get(
        `/${URL.clients}/${id}`
      )
      if (response.statusText === 'OK') {
        return response
      }
      throw new Error()
    } catch (error) {
      console.error(error)
      throw error
    }
  }
)

// interface FetchEditClient  any;
export const fetchEditClient = createAsyncThunk(
  'clients/fetchEditClient',
  async (
    {
      isActive,
      district,
      address,
      entity,
      entityName,
      name,
      email,
      phones,
      countryIso,
      countryName,
      cityId,
      cityName,
      logo,
      requisitesInn,
      requisitesBankBik,
      requisitesBankSwift,
      requisitesBankAccount,
      requisitesBankName,
      requisitesAddress,
      contracts = [],

      id = undefined,
    }: any,
    thunkAPI
  ) => {
    const formData = new FormData()
    formData.append('active', isActive ? '1' : '0')

    formData.append('country[iso]', countryIso)
    formData.append('country[name]', countryName)

    formData.append('city_id', cityId)

    // formData.append('city[id]', cityId)
    // formData.append('city[name]', cityName)

    formData.append('district', district)
    formData.append('address', address)
    formData.append('entity', entity ? '1' : '0')
    formData.append('entity_name', entityName)
    formData.append('name', name)
    formData.append('email', email)

    formData.append('requisites[inn]', requisitesInn)
    formData.append('requisites[bank_bik]', requisitesBankBik)
    formData.append('requisites[bank_swift]', requisitesBankSwift)
    formData.append('requisites[bank_account]', requisitesBankAccount)
    formData.append('requisites[bank_name]', requisitesBankName)
    formData.append('requisites[address]', requisitesAddress)

    if (logo) {
      formData.append('logo', logo, logo.name)
    }

    if (id) {
      formData.set('_method', 'put')
    }
    try {
      const response = await axiosInstance.post(
        `/${URL.clients}${id ? `/${id}` : ''}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )

      if (response.statusText === 'OK' || response.statusText === 'Created') {
        return response.data
      }
      throw new Error('Не удалось отправить данные')
    } catch (error) {
      const typedError = error as MyKnownError

      return thunkAPI.rejectWithValue(
        typedError?.response?.data?.message || 'Ошибка при отправке данных'
      )
    }
  }
)

export const fetchDeleteClient = createAsyncThunk(
  'users/fetchDeleteClient',
  async (_, thunkAPI) => {
    const {
      clients: { currentClient },
    } = thunkAPI.getState() as RootState

    try {
      const response = await axiosInstance.delete(
        `/${URL.clients}/${currentClient?.id}`
      )

      if (response.statusText === 'OK' || response.statusText === 'Created') {
        return response.data
      }
      throw new Error('Не удалось отправить данные')
    } catch (error) {
      console.error('Ошибка при отправке данных:', error)
      return thunkAPI.rejectWithValue('Ошибка при отправке данных')
    }
  }
)

export const clientsSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    setCurrentClient: (state, action) => {
      state.currentClient = action.payload
    },
    setCurrentClientByRequest: (state, action) => {
      state.currentClientByRequest = action.payload
    },
    setIsVisibleModalClient: (state, action) => {
      state.isVisibleModalClient = action.payload
    },
    setIsVisibleDeleteModalClient: (state, action) => {
      state.isVisibleDeleteModalClient = action.payload
    },
    setCurrentClientPhone: (state, action) => {
      state.clients = state.clients.map((item) => {
        if (item.id === state?.currentClientByRequest?.id) {
          //@ts-ignore
          item.phones.push([action.payload])
        }
        return item
      })
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchClients.pending, (state) => {
        state.isPendingGetClient = true
      })
      .addCase(fetchClients.fulfilled, (state, action) => {
        state.clients = action.payload
        state.isPendingGetClient = false
      })
      .addCase(fetchClients.rejected, (state, action) => {
        toast.error(action.error.message)
        state.isPendingGetClient = false
      })
      .addCase(fetchClient.pending, (state) => {
        state.isPendingGetClient = true
      })
      .addCase(fetchClient.fulfilled, (state, action) => {
        state.currentClientByRequest = action.payload.data
        state.isPendingGetClient = false
      })
      .addCase(fetchClient.rejected, (state, action) => {
        toast.error(action.error.message)
        state.isPendingGetClient = false
      })
    builder.addCase(fetchDeleteClient.pending, (state) => {
      return state
    })
    builder.addCase(fetchDeleteClient.fulfilled, (state) => {
      toast.success('Удалено!')

      return {
        ...state,
        isVisibleDeleteModalClient: false,
        currentClient: null,
        clients: state.clients.filter(
          (item) => item.id !== state?.currentClient?.id
        ),
      }
    })
    builder
      .addCase(fetchDeleteClient.rejected, (state, action) => {
        toast.error(action.payload as string)

        return state
      })
      .addCase(fetchEditClient.pending, (state) => {
        state.isPendingGetClient = true
        state.isPendingAddClient = true
        state.isFulfilledAddClient = false
        state.isRejectAddClient = false
      })
      .addCase(fetchEditClient.fulfilled, (state, action) => {
        toast.success('Сохранено!')

        const isClientExist = current(state).clients.find(
          (item) => item.id === action.payload.id
        )

        if (isClientExist) {
          state.clients = state.clients.map((client) =>
            client.id === action.payload.id ? action.payload : client
          )
        } else {
          state.clients = [action.payload, ...state.clients]
        }

        state.isPendingAddClient = false
        state.isFulfilledAddClient = true
        state.isRejectAddClient = false
        state.isPendingGetClient = false
        state.isVisibleModalClient = false
      })
      .addCase(fetchEditClient.rejected, (state, action) => {
        toast.error(action.payload as string)
        //@ts-ignore
        toast.error(action.error.payload)
        state.isPendingGetClient = false
        // state.isVisibleModalClient = false
        state.isPendingAddClient = false
        state.isFulfilledAddClient = false
        state.isRejectAddClient = true
      })
  },
})

export const {
  setCurrentClient,
  setIsVisibleModalClient,
  setIsVisibleDeleteModalClient,
  setCurrentClientByRequest,
  setCurrentClientPhone,
} = clientsSlice.actions

export default clientsSlice.reducer
