import { useEffect, useState } from 'react'

import { NavLink, useLocation } from 'react-router-dom'
import { PATH } from '../../constants/path'
import { getRole } from '../../utils/getRole'

import styles from './AsideMenu.module.css'
import classNames from 'classnames'

const LINKS = [
  {
    path: PATH.WAYBILLS,
    iconStyle: 'iconRouteWhite',
    title: 'Путевые листы',
    roles: ['isAdmin', 'isOwner'],
  },
  {
    path: PATH.DOCUMENTS,
    iconStyle: 'iconDocsWhite',
    title: 'Документы',
    roles: ['isAdmin', 'isOwner'],
  },
  {
    path: PATH.CLIENTS,
    iconStyle: 'iconFlagWhite',
    title: 'Клиенты',
    roles: ['isAdmin', 'isOwner'],
  },
  {
    path: PATH.REPORTS,
    iconStyle: 'iconReportsWhite',
    title: 'Отчёты',
    roles: ['isAdmin', 'isOwner'],
  },
  {
    path: PATH.MY_REPORTS,
    iconStyle: 'iconReportsWhite',
    title: 'Мои отчёты',
    roles: ['isDriver', 'isAdmin', 'isOwner'],
  },
  {
    path: PATH.EMPLOYEES,
    iconStyle: 'iconUsersWhite',
    title: 'Сотрудники',
    roles: ['isAdmin', 'isOwner'],
  },
  {
    path: PATH.CARS,
    iconStyle: 'iconTruckWhite',
    title: 'Транспорт',
    roles: ['isAdmin', 'isOwner'],
  },
  {
    path: PATH.PARTS,
    iconStyle: 'iconPartsWhite',
    title: 'Запчасти',
    roles: ['isAdmin', 'isOwner'],
  },
  {
    path: PATH.PROFILE,
    iconStyle: 'iconProfileWhite',
    title: 'Мой аккаунт',
    roles: ['isAdmin', 'isOwner', 'isDriver'],
  },
  {
    path: PATH.SETTINGS,
    iconStyle: 'iconSettings',
    title: 'Настройки',
    roles: ['isAdmin', 'isOwner'],
  },
] as const

function AsideMenu() {
  const roles = getRole()
  // const [retrievedObject, setRetrievedObject] = useState<any>()

  const item = localStorage.getItem('init')

  // useEffect(() => {
  //   if (item) {
  //     setRetrievedObject(JSON.parse(item))
  //   }
  // }, [item])
  const location = useLocation()

  return (
    <div>
      <div className={styles.accountLeftPanel}>
        <NavLink to="/profile" className={styles.logo}>
          <img
            alt="logo"
            className={styles.img}
            src="img/logo/logo-white.svg"
          />
        </NavLink>
        <div className={classNames(styles.leftMenuAccount, styles.small)}>
          {LINKS.filter((link) => link.roles.some((role) => roles[role])).map(
            ({ title, path, iconStyle }) => (
              <NavLink
                key={path}
                className={classNames(
                  styles.navLink,
                  location.pathname === path && styles.active
                )}
                to={path}
              >
                <i className={`${styles.icon} ${styles[iconStyle]}`} />{' '}
                <span>{title}</span>
              </NavLink>
            )
          )}
        </div>
      </div>
    </div>
  )
}

export default AsideMenu
