import { useEffect, useState } from 'react'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Car,
  fetchParts,
  setCurrentPart,
  setIsVisibleDeleteModalPart,
  setIsVisibleModalPart,
} from '../../redux/reducers/parts'
import { setIsVisibleModalCar, setCurrentCar } from '../../redux/reducers/cars'
import { fetchCars } from '../../redux/reducers/cars'

import { AppDispatch } from '../../redux/store'
import { carsSelector } from '../../redux/selectors/cars-selector'
import HeaderMain from '../../components/header-main/HeaderMain'

import styles from './Parts.module.css'
import classNames from 'classnames'
import Spinner from '../../components/spinner/Spinner'
import EditButton from '../../components/buttons/actions-button/EditButton'
import DeleteButton from '../../components/buttons/actions-button/DeleteButton'
import { partsSelector } from '../../redux/selectors/parts-selector'
import { ListDropdown } from '../../components/modal/part-modal/ListDropDown'

interface PartsProps {
  id: number
  number: string
  name: string
  amount: number
  currency: string
  cars: Car[]
}

function Parts() {
  const dispatch = useDispatch<AppDispatch>()
  const { parts = [], isPendingGetParts } = useSelector(partsSelector)
  const { cars } = useSelector(carsSelector)
  const [checkedCars, setCheckedCars] = useState([])
  const [isNumberSort, setNumberSort] = useState(false)
  const [isNameSort, setNameSort] = useState(false)
  const [isAmountSort, setAmountSort] = useState(false)
  const [sortedParts, setSortedParts] = useState<PartsProps[]>([])

  const handleEditPart = (item: PartsProps) => {
    dispatch(setCurrentPart(item))
    dispatch(setIsVisibleModalPart(true))
  }

  const handleDeletePart = (item: PartsProps) => {
    dispatch(setCurrentPart(item))
    dispatch(setIsVisibleDeleteModalPart(true))
  }

  useEffect(() => {
    dispatch(fetchParts())
  }, [dispatch])

  useEffect(() => {
    dispatch(fetchCars())
  }, [])

  useEffect(() => {
    setSortedParts(parts)
  }, [parts])

  const handleCarClick = (car: Car) => {
    dispatch(setCurrentCar(car))
    dispatch(setIsVisibleModalCar(true))
  }

  useEffect(() => {
    if (checkedCars.length === 1 && checkedCars[0] === -1) {
      dispatch(fetchParts())
    } else {
      dispatch(fetchParts(checkedCars))
    }
  }, [checkedCars])

  const handleCheckedCars = (checkedCars = []) => {
    setCheckedCars(checkedCars)
  }

  const handleSortNumber = () => {
    const sortedParts = [...parts].sort((a, b) =>
      isNumberSort
        ? a.number.localeCompare(b.number)
        : b.number.localeCompare(a.number)
    )
    setSortedParts(sortedParts)
    setNumberSort(!isNumberSort)
    setNameSort(false)
    setAmountSort(false)
  }

  const handleSortName = () => {
    const sortedParts = [...parts].sort((a, b) =>
      isNameSort ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name)
    )
    setSortedParts(sortedParts)
    setNumberSort(false)
    setNameSort(!isNameSort)
    setAmountSort(false)
  }

  return (
    <div className={styles.accountContainer}>
      <div className={styles.accountWideContent}>
        <div className={styles.accountWideContentContainer}>
          <HeaderMain />
          <div className="row">
            <div className="col-12 offset-md-1 col-md-10 offset-lg-1 col-lg-10 offset-xl-2 col-xl-8">
              <>
                <div
                  className={classNames(
                    styles.small,
                    'col-12 col-lg-6 pt-1 pb-1 ml-0 pl-0 mr-0 pr-0'
                  )}
                >
                  <ListDropdown
                    data={cars}
                    checkedItems={handleCheckedCars}
                    checkedIds={checkedCars}
                    allItemsName={'Все машины'}
                  />
                </div>
                {isPendingGetParts ? (
                  <Spinner />
                ) : (
                  <>
                    <div
                      className={classNames(
                        styles.docItem,
                        styles.small2,
                        'doc-item small-2 pl-2 pr-2 small-2 doc-item mt-4 mb-2'
                      )}
                    >
                      <div className="row">
                        <div
                          className={classNames(
                            `d-none d-lg-block col-12 col-sm-3 col-md-12 col-lg-3 col-xl-2`,
                            styles.clickable,
                            { [styles.active]: isNumberSort }
                          )}
                          onClick={handleSortNumber}
                        >
                          Номер
                        </div>
                        <div
                          className={classNames(
                            'd-none d-lg-block col-8 col-sm-7 col-md-5 col-lg-3 col-xl-4',
                            styles.clickable,
                            { [styles.active]: isNameSort }
                          )}
                          onClick={handleSortName}
                        >
                          <span className="color-gray table-sort-col sort-down">
                            Наименование
                          </span>
                        </div>
                        <div
                          className={classNames(
                            'col-4 col-sm-2 col-md-2 col-lg-2 col-xl-2 pr-2',
                            { [styles.active]: isAmountSort }
                          )}
                        >
                          <span className="color-gray table-sort-col">
                            Стоимость
                          </span>
                        </div>
                        <div className="d-none d-lg-block col-9 col-sm-9 col-md-3 col-lg-2 col-xl-2">
                          Машины
                        </div>
                      </div>
                    </div>
                    {sortedParts.map((item) => (
                      <div
                        key={item.id}
                        className={classNames(
                          styles.bgWhite,
                          styles.border10,
                          styles.bxShadow,
                          styles.small2,
                          styles.docItem,
                          `bg-white pl-2 pr-2 border-10 bx-shadow mb-3 small-2 doc-item`
                        )}
                      >
                        <div className="row align-items-center">
                          <div className="doc-number col-12 col-sm-3 col-md-12 col-lg-3 col-xl-2 small-3 pr-2 pt-1 pb-1">
                            {item.number}
                          </div>
                          <div
                            className={classNames(
                              styles.colorBlue,
                              'col-8 col-sm-7 col-md-5 col-lg-3 col-xl-4 small-3 pr-3 pt-1 pb-1'
                            )}
                            onClick={() => handleEditPart(item)}
                          >
                            <b>{item.name}</b>
                          </div>
                          <div
                            className={classNames(
                              styles.colorRed,
                              styles.small2,
                              'doc-money col-4 col-sm-2 col-md-2 col-lg-2 col-xl-2 pt-1 pb-1'
                            )}
                          >
                            <b className="color-red nowrap">
                              {formatMoney(item.amount, item.currency)}
                            </b>
                          </div>

                          <div
                            className={classNames(
                              styles.small3,
                              'doc-car col-9 col-sm-9 col-md-3 col-lg-2 col-xl-2 small-3 pr-2 pt-1 pb-1'
                            )}
                          >
                            {item.cars.map((car, index) => (
                              <React.Fragment key={car.id}>
                                <a
                                  href="javascript:void(0)"
                                  onClick={() => handleCarClick(car)}
                                  className={classNames(
                                    styles.colorBlue,
                                    'car-link'
                                  )}
                                >
                                  {car.name}
                                </a>
                                {index < item.cars.length - 1 && ', '}
                              </React.Fragment>
                            ))}
                          </div>
                          <div
                            className="doc-buttons col-3 col-md-2 col-lg-2  col-xl-2 pt-1 pb-1"
                            style={{ textAlign: 'right' }}
                          >
                            <EditButton onClick={() => handleEditPart(item)} />
                            <DeleteButton
                              onClick={() => handleDeletePart(item)}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function formatMoney(amount: string | number, currencyType: string): string {
  const num = typeof amount === 'string' ? parseFloat(amount) : amount
  const formattedAmount = num.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

  let currencySymbol = ''
  switch (currencyType) {
    case 'USD':
      currencySymbol = '$'
      break
    case 'EUR':
      currencySymbol = '€'
      break
    case 'RUB':
      currencySymbol = '₽'
      break
    case 'BYN':
      currencySymbol = 'Br'
      break
    default:
      currencySymbol = 'E'
  }

  if (num < 1) {
    return `0.${formattedAmount.split('.')[1]} ${currencySymbol}`
  }

  return `${formattedAmount} ${currencySymbol}`
}

export default Parts
