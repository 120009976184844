import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchCars,
  setCurrentCar,
  setIsVisibleDeleteModalCar,
  setIsVisibleModalCar,
} from '../../redux/reducers/cars'
import { AppDispatch } from '../../redux/store'
import { carsSelector } from '../../redux/selectors/cars-selector'
import HeaderMain from '../../components/header-main/HeaderMain'

import styles from './Cars.module.css'
import classNames from 'classnames'
import { getRole } from '../../utils/getRole'
import { companySelector } from '../../redux/selectors/company-selector'
import Spinner from '../../components/spinner/Spinner'
import { isLessThanAMonth } from '../../utils/date'
import EditButton from '../../components/buttons/actions-button/EditButton'
import DeleteButton from '../../components/buttons/actions-button/DeleteButton'

interface CarsProps {
  id: number
  name: string
  image: {
    lg: string
    md: string
    original: string
    sm: string
  }
  active: boolean
}

const { isOwner, isAdmin } = getRole()
function Cars() {
  const dispatch = useDispatch<AppDispatch>()
  const { cars, isPendingGetCars } = useSelector(carsSelector)
  const { currentCompany } = useSelector(companySelector)

  const handleEditCar = (item: CarsProps) => {
    dispatch(setCurrentCar(item))
    dispatch(setIsVisibleModalCar(true))
  }

  const handleDeleteCar = (item: CarsProps) => {
    dispatch(setCurrentCar(item))
    dispatch(setIsVisibleDeleteModalCar(true))
  }

  useEffect(() => {
    dispatch(fetchCars())
  }, [currentCompany?.id, dispatch])

  return (
    <div className={styles.accountContainer}>
      <div className={styles.accountWideContent}>
        <div className={styles.accountWideContentContainer}>
          <HeaderMain />
          {/* ADD CAR */}
          <div className="row">
            <div className="col-12 offset-md-1 col-md-10 offset-lg-1 col-lg-10  offset-xl-2 col-xl-8">
              <>
                {isPendingGetCars ? (
                  <Spinner />
                ) : (
                  cars.map((item) => (
                    <div
                      key={item.id}
                      className={classNames(
                        `pl-3 pr-3 ${styles.bgWhite} pt-2 pb-2 ${styles.border5} ${styles.bxShadow} mb-3`
                      )}
                    >
                      <div className="row">
                        <div className="col-12 col-sm-3">
                          <a className={styles.carImg}>
                            <img
                              onClick={() => handleEditCar(item)}
                              src={item.image.md || 'img/cars/no-car-photo.svg'}
                            />
                          </a>
                        </div>
                        <div className="col-8 col-sm-6 mt-1">
                          <div className={`${styles.fontBig} mb-2`}>
                            <a onClick={() => handleEditCar(item)}>
                              <b>{item.name}</b>
                            </a>

                            <b
                              className={classNames(
                                styles.small2,
                                styles.bgGray,
                                styles.borderRadius5
                              )}
                            >
                              {item.cargo_volume}&nbsp;м<sup>3</sup>
                              &nbsp;&nbsp;/&nbsp;&nbsp;
                              {item.cargo_weight / 1000}&nbsp;т
                            </b>
                          </div>

                          {item.active ? (
                            <div className={styles.small2}>
                              <i
                                className={classNames(
                                  styles.icon,
                                  styles.iconVerified2,
                                  styles.icon15
                                )}
                              />
                              <span> Действует</span>
                            </div>
                          ) : (
                            <div
                              className={classNames(
                                styles.small2,
                                styles.colorRed
                              )}
                            >
                              Неактивна
                            </div>
                          )}

                          {item.state_inspection_end &&
                            isLessThanAMonth(item.state_inspection_end) && (
                              <div
                                className={classNames(
                                  styles.small2,
                                  isLessThanAMonth(item.state_inspection_end) &&
                                    styles.colorRed,
                                  'mt-1'
                                )}
                              >
                                <i
                                  className={classNames(
                                    'mr-1',
                                    styles.icon,
                                    styles.icon15,
                                    isLessThanAMonth(item.state_inspection_end)
                                      ? styles.iconCalendRed
                                      : styles.iconCalendBlack
                                  )}
                                ></i>{' '}
                                <span>
                                  Техосмотр &ndash; {item.state_inspection_end}
                                </span>
                              </div>
                            )}
                          {item.insurance_end_rb &&
                            isLessThanAMonth(item.insurance_end_rb) && (
                              <div
                                className={classNames(
                                  styles.small2,
                                  isLessThanAMonth(item.insurance_end_rb) &&
                                    styles.colorRed,
                                  'mt-1'
                                )}
                              >
                                <i
                                  className={classNames(
                                    styles.icon,
                                    styles.icon15,
                                    isLessThanAMonth(item.insurance_end_rb)
                                      ? styles.iconCalendRed
                                      : styles.iconCalendBlack,
                                    'mr-1'
                                  )}
                                ></i>{' '}
                                <span>
                                  Страховка РБ &ndash;{' '}
                                  {item.insurance_end_rb || ''}
                                </span>
                              </div>
                            )}
                          {item.insurance_end_rf &&
                            isLessThanAMonth(item.insurance_end_rf) && (
                              <div
                                className={classNames(
                                  styles.small2,
                                  isLessThanAMonth(item.insurance_end_rf) &&
                                    styles.colorRed,
                                  'mt-1'
                                )}
                              >
                                <i
                                  className={classNames(
                                    styles.icon,
                                    styles.icon15,
                                    isLessThanAMonth(item.insurance_end_rf)
                                      ? styles.iconCalendRed
                                      : styles.iconCalendBlack,
                                    'mr-1'
                                  )}
                                ></i>{' '}
                                <span>
                                  Страховка РФ &ndash;{' '}
                                  {item.insurance_end_rf || ''}
                                </span>
                              </div>
                            )}
                        </div>
                        <div
                          className="col-4 col-sm-3 mt-1"
                          style={{ textAlign: 'right' }}
                        >
                          <EditButton onClick={() => handleEditCar(item)} />
                          <DeleteButton onClick={() => handleDeleteCar(item)} />
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Cars
