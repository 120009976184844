import React from 'react'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../redux/store'
import styles from './DeleteModal.module.css'
import classNames from 'classnames'

const DeleteModal = ({ title, onConfirm, onCancel }: any) => {
  const dispatch = useDispatch<AppDispatch>()

  return (
    <div style={{ display: 'block' }} className={styles.win}>
      <div className={styles.winContainer}>
        <div className={styles.winContent}>
          <div className={styles.close} onClick={() => dispatch(onCancel)}>
            ×
          </div>
          <div className={styles.winContentFulltext}>
            <h4
              style={{ textAlign: 'center' }}
              className={classNames(styles.colorRed, styles.h4, 'mb-5')}
            >
              Вы уверены, что хотите удалить {title}?
            </h4>
            {/* <p className={styles.p} style={{ textAlign: 'center' }}>
              {title !== 'Report' &&
                `All reports related to this ${title.toLowerCase()} will remain available.`}
            </p> */}

            <div className="row mt-2">
              <div
                className="col-12 col-sm-8 mt-1 mb-1"
                style={{ textAlign: 'right' }}
              >
                <button
                  onClick={() => dispatch(onConfirm)}
                  className={`${styles.btn} d-block d-sm-inline-block`}
                >
                  ДА, Я УВЕРЕН
                </button>
              </div>
              <div
                className="col-12 col-sm-4 mt-1 mb-1"
                style={{ textAlign: 'right' }}
              >
                <div
                  onClick={() => dispatch(onCancel)}
                  className={`${styles.btn} ${styles.btnSmall} ${styles.btnBlueOutline} d-block d-sm-inline-block`}
                >
                  Отмена
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DeleteModal
