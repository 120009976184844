import React from 'react'
import Datetime from 'react-datetime'
import moment, { Moment } from 'moment'
import 'moment/locale/ru'
import 'react-datetime/css/react-datetime.css'
import styles from './DatePicker.module.css'
import classNames from 'classnames'

moment.locale('ru')

interface DateTimePickerProps {
  id: string
  selectedDate: string | Moment
  setSelectedDate: (date: string) => void
  placeholder?: string
  addPosition?: boolean
  isValidateDate?: boolean
}

const DateTimePicker: React.FC<DateTimePickerProps> = ({
  id,
  selectedDate,
  setSelectedDate,
  placeholder,
  addPosition,
  isValidateDate = true,
}) => {
  const handleDateChange = (date: Moment | string) => {
    if (moment.isMoment(date) && date.isValid()) {
      if (isValidateDate && date.isAfter(moment(), 'day')) {
        setSelectedDate('')
      } else {
        setSelectedDate(date.format('YYYY-MM-DD HH:mm'))
      }
    } else if (
      typeof date === 'string' &&
      moment(date, 'YYYY-MM-DD HH:mm', true).isValid()
    ) {
      const inputDate = moment(date, 'YYYY-MM-DD HH:mm', true)
      if (isValidateDate && inputDate.isAfter(moment(), 'day')) {
        setSelectedDate('')
      } else {
        setSelectedDate(date)
      }
    } else {
      setSelectedDate('')
    }
  }

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const inputDate = moment(event.target.value, 'YYYY-MM-DD HH:mm', true)

    if (!isValidateDate) {
      return
    }
    if (inputDate.isValid() && inputDate.isAfter(moment(), 'day')) {
      setSelectedDate('')
    }
  }

  const isValidDate = (current: Moment) => {
    return current.isSameOrBefore(moment(), 'day')
  }

  return (
    <div className={styles.container}>
      <Datetime
        inputProps={{
          id,
          placeholder: placeholder || undefined,
          autoComplete: 'off',
          onBlur: handleBlur,
        }}
        value={selectedDate ? moment(selectedDate, 'YYYY-MM-DD HH:mm') : ''}
        onChange={handleDateChange}
        className={classNames(
          styles.calendar,
          addPosition ? styles.addPosition : ''
        )}
        locale="ru"
        isValidDate={isValidateDate ? isValidDate : undefined}
      />
      <div className={styles.calendarImage} />
    </div>
  )
}

export default DateTimePicker
