import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import Cars from './pages/cars/Cars'
import Companies from './pages/companies/Companies'
import Employees from './pages/employees/Employees'
import Profile from './pages/profile/Profile'
import { PATH } from './constants/path'
import Info404 from './pages/errors/Info404'
import Reports from './pages/reports/Reports'
import MyReports from './pages/my-reports/MyReports'
import ProtectedRoute from './ProtectedRoute'
import { getRole } from './utils/getRole'
import Clients from './pages/clients/Clients'
import Settings from './pages/settings/Settings'
import Documents from './pages/documents/DocumentsContracts'
import Parts from './pages/parts/Parts'

const { isAdmin, isOwner, isDriver } = getRole()

const AppRoutes = () => {
  return (
    <Switch>
      <Route
        exact
        path="/"
        render={() => {
          if (isDriver) {
            return <Redirect to={PATH.MY_REPORTS} />
          } else if (isAdmin || isOwner) {
            return <Redirect to={PATH.REPORTS} />
          } else {
            return <Redirect to="/login" />
          }
        }}
      />
      <ProtectedRoute
        path={PATH.COMPANIES}
        allowedRoles={['isAdmin', 'isOwner']}
        component={Companies}
      />
      <ProtectedRoute
        path={PATH.REPORTS}
        allowedRoles={['isAdmin', 'isOwner']}
        component={Reports}
      />
      <ProtectedRoute
        path={PATH.MY_REPORTS}
        allowedRoles={['isDriver', 'isOwner', 'isAdmin']}
        component={MyReports}
      />
      <ProtectedRoute
        path={PATH.EMPLOYEES}
        allowedRoles={['isAdmin', 'isOwner']}
        component={Employees}
      />
      <ProtectedRoute
        path={PATH.CARS}
        allowedRoles={['isAdmin', 'isOwner']}
        component={Cars}
      />
      <ProtectedRoute
        path={PATH.PROFILE}
        allowedRoles={['isAdmin', 'isOwner', 'isDriver']}
        component={Profile}
      />
      <ProtectedRoute
        path={PATH.CLIENTS}
        allowedRoles={['isAdmin', 'isOwner']}
        component={Clients}
      />
      <ProtectedRoute
        path={PATH.SETTINGS}
        allowedRoles={['isAdmin', 'isOwner']}
        component={Settings}
      />
      <ProtectedRoute
        path={PATH.DOCUMENTS}
        allowedRoles={['isAdmin', 'isOwner']}
        component={Documents}
      />
      <ProtectedRoute
        path={PATH.PARTS}
        allowedRoles={['isAdmin', 'isOwner']}
        component={Parts}
      />
      <Route component={Info404} />
    </Switch>
  )
}

export default AppRoutes
