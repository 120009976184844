import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit'
import { URL } from '../../constants/url'
import { MyKnownError } from '../types/common'
import { AxiosResponse } from 'axios'
import axiosInstance from '../../utils/axiosInstance'
import { RootState } from '../store'
import { toast } from 'react-toastify'
import { getRole } from '../../utils/getRole'

export interface User {
  id: number
  name: string
  email: string
  avatar: {
    lg: string
    md: string
    original: string
    sm: string
  }
  active: boolean
  role: string
}

export interface Car {
  id: number
  name: string
  active: boolean
  image: {
    lg: string
    md: string
    original: string
    sm: string
  }
}

interface Part {
  id: number
  name: string
  number: string
  amount: number
  currency: string
  cars: Car[]
}

axiosInstance.interceptors.request.use(
  (config) => {
    return config
  },
  (error) => {
    console.error('Ошибка при отправке запроса:', error)
    return Promise.reject(error)
  }
)

export const fetchParts = createAsyncThunk(
  'parts/all',
  async (checkedCars: string[] | undefined, thunkAPI) => {
    const params = new URLSearchParams()

    if (checkedCars && checkedCars.length > 0) {
      const carIds = checkedCars.join(',')
      params.append('car_ids', carIds)
    }

    try {
      const response: AxiosResponse = await axiosInstance.get(`/${URL.parts}`, {
        params,
      })

      if (response.statusText === 'OK') {
        return response.data
      }

      throw new Error()
    } catch (error) {
      const typedError = error as MyKnownError

      return thunkAPI.rejectWithValue(
        typedError?.response?.data?.message || 'Ошибка при отправке данных'
      )
    }
  }
)

type FetchEditPart = {
  id: number
  name: string
  amount: number
  number: string
  currency: any
  // checkedCars?: [];
  checkedCars?: number[]
}

export interface PartsState {
  currentPart: Part | null
  parts: Array<Part>
  isVisibleModalPart: boolean
  isVisibleDeleteModalPart: boolean
  isPendingGetParts: boolean
  currentPartForEdit: Part | null
  isPendingAddPart: boolean
  isFulfilledAddPart: boolean
  isRejectAddCPart: boolean
}

const initialState: PartsState = {
  currentPart: null,
  isVisibleModalPart: false,
  isVisibleDeleteModalPart: false,
  parts: [],
  currentPartForEdit: null,
  isPendingGetParts: true,
  isPendingAddPart: false,
  isFulfilledAddPart: false,
  isRejectAddCPart: false,
}

export const addPart = createAsyncThunk(
  'parts/store',
  async (
    { name, number, amount, currency, checkedCars }: FetchEditPart,
    thunkAPI
  ) => {
    const formData = new FormData()
    formData.append('name', name)
    formData.append('number', number)
    formData.append('amount', amount.toString())
    formData.append('currency', currency)
    if (
      checkedCars &&
      (checkedCars ?? []).length > 0 &&
      !checkedCars.includes(-1)
    ) {
      checkedCars.forEach((value) => {
        formData.append('car_ids[]', value.toString())
      })
    } else {
      formData.append('all_cars', '1')
    }

    try {
      const response = await axiosInstance.post(`/${URL.parts}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })

      if (response.statusText === 'OK' || response.statusText === 'Created') {
        return response.data
      }
      throw new Error('Не удалось отправить данные')
    } catch (error) {
      const typedError = error as MyKnownError

      return thunkAPI.rejectWithValue(
        typedError?.response?.data?.message || 'Ошибка при отправке данных'
      )
    }
  }
)

export const editPart = createAsyncThunk(
  'parts/update',
  async (
    { id, name, number, amount, currency, checkedCars }: FetchEditPart,
    thunkAPI
  ) => {
    const formData = new FormData()
    formData.append('name', name)
    formData.append('number', number)
    formData.append('amount', amount.toString())
    formData.append('currency', currency)

    if (
      checkedCars &&
      (checkedCars ?? []).length > 0 &&
      !checkedCars.includes(-1)
    ) {
      checkedCars.forEach((value) => {
        formData.append('car_ids[]', value.toString())
      })
    } else {
      formData.append('all_cars', '1')
    }

    formData.set('_method', 'put')
    try {
      const response = await axiosInstance.post(
        `/${URL.parts}/${id}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )

      if (response.statusText === 'OK' || response.statusText === 'Created') {
        return response.data
      }
      throw new Error('Не удалось отправить данные')
    } catch (error) {
      const typedError = error as MyKnownError

      return thunkAPI.rejectWithValue(
        typedError?.response?.data?.message || 'Ошибка при отправке данных'
      )
    }
  }
)

export const deletePart = createAsyncThunk(
  'parts/delete',
  async (_, thunkAPI) => {
    const {
      parts: { currentPart },
    } = thunkAPI.getState() as RootState

    try {
      const response = await axiosInstance.delete(
        `/${URL.parts}/${currentPart?.id}`
      )

      if (response.statusText === 'OK' || response.statusText === 'Created') {
        return response.data
      }
      throw new Error('Не удалось отправить данные')
    } catch (error) {
      return thunkAPI.rejectWithValue('Ошибка при отправке данных')
    }
  }
)

export const partsSlice = createSlice({
  name: 'parts',
  initialState,
  reducers: {
    setCurrentPart: (state, action) => {
      return { ...state, currentPart: action.payload }
    },
    setIsVisibleModalPart: (state, action: { payload: boolean }) => {
      return {
        ...state,
        isVisibleModalPart: action.payload,
        currentPart: action.payload ? state.currentPart : null,
      }
    },
    setIsVisibleDeleteModalPart: (state, action: { payload: boolean }) => ({
      ...state,
      isVisibleDeleteModalPart: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchParts.pending, (state) => {
      return { ...state, isPendingGetParts: true }
    })
    builder.addCase(fetchParts.fulfilled, (state, action) => {
      return { ...state, parts: action.payload, isPendingGetParts: false }
    })
    builder.addCase(fetchParts.rejected, (state, action) => {
      toast.error(action.payload as string)

      return { ...state, isPendingGetParts: false }
    })

    builder.addCase(editPart.pending, (state) => {
      return {
        ...state,
        isPendingAddPart: true,
        isFulfilledAddPart: false,
        isRejectAddPart: false,
      }
    })
    builder.addCase(editPart.fulfilled, (state, action) => {
      toast.success('Сохранено!')

      return {
        ...state,
        isPendingAddPart: false,
        isFulfilledAddPart: true,
        isRejectAddPart: false,
        isVisibleModalPart: false,
        parts: state.parts
          .filter((item) => item.number !== action.payload.number)
          .concat(action.payload),
      }
    })

    // builder.addCase(editPart.fulfilled, (state, action) => {
    //   toast.success('Сохранено!')

    //   const isPartExist = current(state).parts.find(
    //     (item) => item.number === action.payload.number
    //   )

    //   if (isPartExist) {
    //     return {
    //       ...state,
    //       isPendingAddPart: false,
    //       isFulfilledAddPart: true,
    //       isRejectAddPart: false,
    //       isVisibleModalPart: false,
    //       parts: state.parts.map((item) =>
    //         item.number === action.payload.number ? action.payload : item
    //       ),
    //     }
    //   }
    //   return {
    //     ...state,
    //     isPendingAddPart: false,
    //     isFulfilledAddPart: true,
    //     isRejectAddPart: false,
    //     parts: [action.payload, ...state.parts],
    //     isVisibleModalPart: false,
    //   }
    // })
    builder.addCase(editPart.rejected, (state, action) => {
      toast.error(action.payload as string)

      return {
        ...state,
        isPendingAddPart: false,
        isFulfilledAddPart: false,
        isRejectAddPArt: true,
      }
    })

    builder.addCase(addPart.pending, (state) => {
      return state
    })
    builder.addCase(addPart.fulfilled, (state, action) => {
      toast.success('Сохранено!')

      return {
        ...state,

        parts: [action.payload, ...state.parts],
        isVisibleModalPart: false,
      }
    })
    builder.addCase(addPart.rejected, (state, action) => {
      toast.error(action.payload as string)

      return state
    })

    builder.addCase(deletePart.pending, (state) => {
      return state
    })
    builder.addCase(deletePart.fulfilled, (state, action) => {
      toast.success('Успешно удалено!')

      return {
        ...state,
        isVisibleDeleteModalPart: false,
        currentPart: null,
        parts: state.parts.filter(
          (part) => part.number !== state?.currentPart?.number
        ),
      }
    })
    builder.addCase(deletePart.rejected, (state, action) => {
      toast.error(action.payload as string)

      return state
    })
  },
})

export const {
  setCurrentPart,
  setIsVisibleModalPart,
  setIsVisibleDeleteModalPart,
} = partsSlice.actions

export default partsSlice.reducer
