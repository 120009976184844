import { useState } from 'react'
import { Link } from 'react-router-dom'
import { PATH } from '../../constants/path'

import styles from './Settings.module.css'
import { AppDispatch } from '../../redux/store'
import { useDispatch } from 'react-redux'
import { fetchForgotPassword } from '../../redux/reducers/auth'
import classNames from 'classnames'
import HeaderMain from '../../components/header-main/HeaderMain'

function Settings() {
  const dispatch = useDispatch<AppDispatch>()
  const [currentTab, setCurrentTab] = useState(1)

  // const { clients } = useSelector(clientsSelector)
  // const [selectedCountry, setSelectedCountry] = useState('all')
  // const [isVisibleDropdownPhones, setIsVisibleDropdownPhones] = useState(false)

  // const handleEditClient = async (item: Client) => {
  //   await dispatch(fetchClient(item.id))
  //   dispatch(setIsVisibleModalClient(true))
  // }

  // const handleDeleteClient = (item: Client) => {
  //   dispatch(setCurrentClient(item))
  //   dispatch(setIsVisibleDeleteModalClient(true))
  // }

  // const handleCountryChange = (countryIso: string) => {
  //   setSelectedCountry(countryIso)
  // }

  // const filteredClients = clients.filter((client) => {
  //   return selectedCountry === 'all' || client.country.iso === selectedCountry
  // })

  // useEffect(() => {
  //   dispatch(fetchClients())
  // }, [])

  interface VisibleDropdowns {
    [key: number]: boolean
  }

  const [visibleDropdowns, setVisibleDropdowns] = useState<VisibleDropdowns>({})

  const toggleDropdown = (clientId: number) => {
    setVisibleDropdowns((prevVisibleDropdowns) => ({
      ...prevVisibleDropdowns,
      [clientId]: !prevVisibleDropdowns[clientId],
    }))
  }

  return (
    <div className={styles.accountContainer}>
      <div className={styles.accountWideContent}>
        <div className={styles.accountWideContentContainer}>
          <HeaderMain />

          <div
            className={classNames(
              'd-none d-sm-flex flex-wrap align-items-end tabs radio-tabs mb-5',
              styles.radioTabs
            )}
            style={{ textAlign: 'center' }}
          >
            <div className="mt-1 mb-1">
              <input
                name="setts_radio"
                type="radio"
                value={1}
                checked={currentTab === 1}
              />
              <label onClick={() => setCurrentTab(1)}>Шаблоны документов</label>
            </div>
            <div className="mt-1 mb-1">
              <input
                name="setts_radio"
                id="setts_radio3"
                type="radio"
                value={2}
                checked={currentTab === 2}
              />
              <label onClick={() => setCurrentTab(2)}>Шаблоны писем</label>
            </div>
            <div className="mt-1 mb-1">
              <input
                name="setts_radio"
                id="setts_radio1"
                type="radio"
                value={3}
                checked={currentTab === 3}
              />
              <label onClick={() => setCurrentTab(3)}>Общие настройки</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Settings
