import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'
import ErrorBoundary from './ErrorBoundary'
import AppRoutes from './AppRoutes'
import Header from './components/header/Header'
import AsideMenu from './components/aside-menu/AsideMenu'
import { PATH } from './constants/path'
import Auth from './pages/auth/Auth'

import './App.css'
import { useEffect } from 'react'
import AddCarModal from './components/modal/car-modal/AddCarModal'
import AddCompanyModal from './components/modal/company-modal/AddCompanyModal'
import { useSelector } from 'react-redux'
import { carsSelector } from './redux/selectors/cars-selector'
import { getCookie } from './utils/getCookies'
import { usersSelector } from './redux/selectors/users-selector'
import {
  fetchDeleteUser,
  setIsVisibleDeleteModalUser,
} from './redux/reducers/users'
import {
  fetchDeleteCar,
  setIsVisibleDeleteModalCar,
} from './redux/reducers/cars'
import DeleteModal from './components/modal/DeleteModal'
import AddUserModal from './components/modal/user-modal/AddUserModal'
import {
  fetchDeleteCompany,
  setIsVisibleDeleteModalCompany,
} from './redux/reducers/company'
// import { getRole } from './utils/getRole'
import { companySelector } from './redux/selectors/company-selector'
import Footer from './components/footer/Footer'
import ReportModal from './components/modal/report-modal/ReportModal'
import { reportsSelector } from './redux/selectors/reports-selector'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import ChangeEmailModal from './components/modal/change-email-modal/ChangeEmailModal'
import { authSelector } from './redux/selectors/auth-selector'
import ResetPasswordModal from './pages/reset-password/ResetPassword'
import {
  fetchDeleteReport,
  setIsVisibleDeleteModalReport,
} from './redux/reducers/reports'
import NewServiceModal from './components/modal/new-service/NewServiceModal'
import { serviceSelector } from './redux/selectors/service-selector'
import { clientsSelector } from './redux/selectors/clients-selector'
import {
  fetchDeleteClient,
  setIsVisibleDeleteModalClient,
} from './redux/reducers/clients'
import { deletePart } from './redux/reducers/parts'
import AddClientModal from './components/modal/client-modal/AddClientModal'
import { contractsSelector } from './redux/selectors/documents/contracts-selector'
import AddContractsModal from './components/modal/documents/contracts/AddContractsModal'

import {
  fetchDeleteContract,
  setIsVisibleDeleteModalContract,
} from './redux/reducers/documents/contracts'
import LoadingPage from './components/loading/LoadingPage'
import { notesSelector } from './redux/selectors/notes-selector'
import { phonesSelector } from './redux/selectors/phones-selector'
import { partsSelector } from './redux/selectors/parts-selector'
import { notificationsSelector } from './redux/selectors/notification-selector'
import PartModal from './components/modal/part-modal/PartModal'
import { setIsVisibleDeleteModalPart } from './redux/reducers/parts'
import {
  deleteNotification,
  setVisibleDeleteModalNotification,
} from './redux/reducers/notifications'
import NotificationsModal from './components/modal/notifications-modal/NotificationsModal'
import {
  fetchDeleteNotes,
  setIsVisibleDeleteNote,
} from './redux/reducers/notes'
import {
  fetchDeleteServices,
  setIsVisibleDeleteService,
} from './redux/reducers/services'
import 'react-datetime/css/react-datetime.css'
import {
  fetchDeleteExpense,
  setVisibleDeleteModalExpenses,
} from './redux/reducers/reports'
import {
  fetchDeletePhones,
  setVisibleDeleteModalPhone,
} from './redux/reducers/phones'

const App = () => {
  const { isPendingContract } = useSelector(contractsSelector)
  const { isPendingAddCar } = useSelector(carsSelector)
  const { isPendingAddNote } = useSelector(notesSelector)
  const { isPendingAddService } = useSelector(serviceSelector)
  const { isPendingGetClient } = useSelector(clientsSelector)
  const { isPendingAddPhone } = useSelector(phonesSelector)
  const { isPendingAddUser } = useSelector(usersSelector)
  const { isPendingAddPart } = useSelector(partsSelector)

  const { isVisibleModalCar, isVisibleDeleteModalCar } =
    useSelector(carsSelector)
  const { isVisibleModalService } = useSelector(serviceSelector)
  const { isVisibleModalUser, isVisibleDeleteModalUser } =
    useSelector(usersSelector)
  const { isVisibleModalCompany, isVisibleDeleteModalCompany } =
    useSelector(companySelector)
  const { isVisibleModalChangeEmail } = useSelector(authSelector)
  const {
    isVisibleModalReport,
    isVisibleDeleteModalReport,
    isVisibleDeleteModalExpenses,
    isPendingAddExpense,
    isPendingDeleteExpense,
    isPendingGetCurrentReports,
    isPendingGetReports,
  } = useSelector(reportsSelector)
  const { isVisibleModalClient, isVisibleDeleteModalClient } =
    useSelector(clientsSelector)
  const { isVisibleDeleteModalContract, isVisibleModalContract } =
    useSelector(contractsSelector)
  const { isVisibleModalPart, isVisibleDeleteModalPart } =
    useSelector(partsSelector)
  const { isVisibleNotifications, isVisibleDeleteModalNotification } =
    useSelector(notificationsSelector)
  const isVisibleLoadingPage =
    isPendingContract ||
    isPendingAddCar ||
    isPendingAddNote ||
    isPendingAddService ||
    isPendingGetClient ||
    isPendingAddPhone ||
    isPendingAddUser ||
    isPendingAddPart ||
    isPendingAddExpense ||
    isPendingDeleteExpense ||
    isPendingGetCurrentReports ||
    isPendingGetReports

  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker
        .register('/service-worker.js')
        .then((registration) => {
          console.log(
            'Service Worker зарегистрирован с областью:',
            registration.scope
          )
        })
        .catch((error) => {
          console.log('Регистрация Service Worker не удалась:', error)
        })
    })
  }

  const { isVisibleDeleteModalPhone } = useSelector(phonesSelector)

  const { isVisibleDeleteNote } = useSelector(notesSelector)
  const { isVisibleDeleteService } = useSelector(serviceSelector)

  useEffect(() => {
    // const { isAdmin, hasRole } = getRole()
    // if (hasRole) {
    //   if (isAdmin) {
    //     dispatch(fetchCompany())
    //   } else {
    //   }
    // }
  }, [])

  return (
    <ErrorBoundary>
      <Router>
        <Switch>
          {!getCookie('auth') && (
            <Route exact path={PATH.AUTH} component={Auth} />
          )}
          <Route
            exact
            path={PATH.RESET_PASSWORD}
            component={ResetPasswordModal}
          />
          <Route
            render={() =>
              getCookie('auth') ? (
                <div className="container-header">
                  <AsideMenu />
                  <div className="container-main">
                    <Header />
                    <AppRoutes />
                  </div>
                </div>
              ) : (
                <Redirect to={PATH.AUTH} />
              )
            }
          />
        </Switch>
      </Router>
      {isVisibleModalService && <NewServiceModal />}
      {isVisibleModalCar && <AddCarModal />}
      {isVisibleModalUser && <AddUserModal />}
      {isVisibleModalCompany && <AddCompanyModal />}
      {isVisibleModalReport && <ReportModal />}
      {isVisibleModalChangeEmail && <ChangeEmailModal />}
      {isVisibleModalClient && <AddClientModal />}
      {isVisibleModalContract && <AddContractsModal />}
      {isVisibleModalPart && <PartModal />}
      {isVisibleNotifications && <NotificationsModal />}

      {isVisibleDeleteModalCar && (
        <DeleteModal
          title="Транспорт"
          onConfirm={fetchDeleteCar()}
          onCancel={setIsVisibleDeleteModalCar(false)}
        />
      )}
      {isVisibleDeleteModalUser && (
        <DeleteModal
          title="Водитель"
          onConfirm={fetchDeleteUser()}
          onCancel={setIsVisibleDeleteModalUser(false)}
        />
      )}
      {isVisibleDeleteModalCompany && (
        <DeleteModal
          title="Company"
          onConfirm={fetchDeleteCompany()}
          onCancel={setIsVisibleDeleteModalCompany(false)}
        />
      )}

      {isVisibleDeleteModalReport && (
        <DeleteModal
          title="Отчёт"
          onConfirm={fetchDeleteReport()}
          onCancel={setIsVisibleDeleteModalReport(false)}
        />
      )}
      {isVisibleDeleteModalClient && (
        <DeleteModal
          title="Клиента"
          onConfirm={fetchDeleteClient()}
          onCancel={setIsVisibleDeleteModalClient(false)}
        />
      )}
      {isVisibleDeleteModalContract && (
        <DeleteModal
          title="Контракт"
          onConfirm={fetchDeleteContract()}
          onCancel={setIsVisibleDeleteModalContract(false)}
        />
      )}
      {isVisibleDeleteModalPart && (
        <DeleteModal
          title="Запчасть"
          onConfirm={deletePart()}
          onCancel={setIsVisibleDeleteModalPart(false)}
        />
      )}
      {isVisibleDeleteModalNotification && (
        <DeleteModal
          title="Уведомление"
          onConfirm={deleteNotification()}
          onCancel={setVisibleDeleteModalNotification(false)}
        />
      )}
      {isVisibleDeleteModalPhone && (
        <DeleteModal
          title="Телефон"
          onConfirm={fetchDeletePhones()}
          onCancel={setVisibleDeleteModalPhone(false)}
        />
      )}
      {isVisibleDeleteModalExpenses && (
        <DeleteModal
          title="Расходы"
          onConfirm={fetchDeleteExpense()}
          onCancel={setVisibleDeleteModalExpenses(false)}
        />
      )}
      {isVisibleDeleteNote && (
        <DeleteModal
          title="Заметку"
          onConfirm={fetchDeleteNotes()}
          onCancel={setIsVisibleDeleteNote(false)}
        />
      )}

      {isVisibleDeleteService && (
        <DeleteModal
          title="Сервис"
          onConfirm={fetchDeleteServices()}
          onCancel={setIsVisibleDeleteService(false)}
        />
      )}

      {/* {(window.location.pathname === PATH.AUTH ||
        window.location.pathname === PATH.RESET_PASSWORD) && <Footer />} */}
      {isVisibleLoadingPage && <LoadingPage />}

      <ToastContainer
        autoClose={1500}
        style={{ zIndex: '10000000' }}
        position="top-center"
      />
    </ErrorBoundary>
  )
}

export default App
