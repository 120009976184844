// Format date to yyyy.mm.dd
export function formatDate(dateString: string, revers?: boolean) {
  const date = new Date(dateString)
  const day = String(date.getDate()).padStart(2, '0')
  const month = String(date.getMonth() + 1).padStart(2, '0') // Месяцы начинаются с 0
  const year = date.getFullYear()

  if (revers) {
    return `${year}.${month}.${day}`
  }
  return `${day}.${month}.${year}`
}

export function isLessThanAMonth(dateString: string): boolean {
  const targetDate = new Date(dateString)
  const today = new Date()

  const diffInMs = targetDate.getTime() - today.getTime()
  const diffInDays = diffInMs / (1000 * 60 * 60 * 24)

  return diffInDays < 30
}

export function formatDateAndTime(dateTime: string): string {
  const date = new Date(dateTime)

  const day = date.getUTCDate().toString().padStart(2, '0')
  const month = (date.getUTCMonth() + 1).toString().padStart(2, '0')
  const year = date.getUTCFullYear().toString()

  const hours = date.getUTCHours().toString().padStart(2, '0')
  const minutes = date.getUTCMinutes().toString().padStart(2, '0')

  return `${day}.${month}.${year} ${hours}:${minutes}`
}

export function getLatestDate(created_at: string, updated_at: string): string {
  const createdDate = new Date(created_at)
  const updatedDate = new Date(updated_at)

  if (updatedDate > createdDate) {
    return updated_at
  } else {
    return created_at
  }
}
