export const onlyNumberRegularCheck = (
  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
) => {
  const value = e.target.value
  // Регулярное выражение для проверки чисел (целых и дробных) с точкой и запятой
  const regex = /^-?\d*[.,]?\d*$/
  return regex.test(value)
}

export const onlyIntegerCheck = (
  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
) => {
  const value = e.target.value
  // Регулярное выражение для проверки только целых чисел
  const regex = /^-?\d*$/
  return regex.test(value)
}
