import { useEffect, useState } from 'react'
import styles from './ReportsCalendar.module.css'
import { DateRange, DefinedRange } from 'react-date-range'

import ru from 'date-fns/locale/ru'

import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css'
import classNames from 'classnames'
import { useOutsideClick } from '../../../hooks/useOutsideClick'
import { staticRangesArray } from '../../../constants/date'
import { setCalendarRange } from '../../../redux/reducers/report-filter'
import { useDispatch } from 'react-redux'

const getSessionStorageData = (key: string) => {
  const data = sessionStorage.getItem('reportFilter')
  return data ? JSON.parse(data)[key] : null
}

function ReportsCalendar({
  calendarRange,
  isVisibleForm,
  setIsVisibleForm,
}: any) {
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(false)
  const [clickCount, setClickCount] = useState(0)
  const [isVisibleCalendar, setIsVisibleCalendar] = useState(false)

  const ref = useOutsideClick(() => {
    setIsOpen(false)
  })

  useEffect(() => {
    if (clickCount >= 2) {
      setIsOpen(false)
      setClickCount(0)
    }
  }, [clickCount])

  useEffect(() => {
    const storedSelectedTrucks = getSessionStorageData('selectedTrucks')
    const storedSelectedEmployees = getSessionStorageData('selectedDrivers')
    if (storedSelectedTrucks?.length || storedSelectedEmployees?.length) {
      setIsVisibleForm(!isVisibleForm)
    }
  }, [])

  return (
    <>
      <div className={classNames(`account-left-col, ${styles.datePicker}`)}>
        <div className="row">
          <div
            className="col-8 col-sm-9 col-md-12 pr-1"
            style={{ textAlign: 'left' }}
          >
            <div className={styles.calendar}>
              <DefinedRange
                className={styles.range}
                onChange={(item) => {
                  dispatch(setCalendarRange([item.selection]))
                }}
                rangeColors={['#11e651']}
                ranges={calendarRange}
                staticRanges={staticRangesArray}
              />
              <DateRange
                className={styles.dateRange}
                rangeColors={['#0f6dba']}
                locale={ru}
                dateDisplayFormat="dd.MM.yyyy"
                editableDateInputs={true}
                onChange={(item) => {
                  dispatch(setCalendarRange([item.selection]))
                }}
                moveRangeOnFirstSelection={false}
                ranges={calendarRange}
                months={2}
              />
            </div>
          </div>
        </div>
      </div>
      {<div className={styles.isMobileMargin} />}
      <div className={styles.dateRangeMobile} ref={ref}>
        <div className={styles.flexInput}>
          <div
            className={styles.inputCalendar}
            onClick={() => {
              setIsVisibleCalendar((prev) => !prev)
              setIsOpen(!isOpen)
            }}
          >
            <input
              type="text"
              readOnly
              //@ts-ignore
              value={`${calendarRange[0].startDate.toLocaleDateString()} - ${calendarRange[0].endDate.toLocaleDateString()}`}
              className={classNames(styles.dateInput)}
            />
            <i
              className={`${styles.iconArrowBlue}  ${styles.iconArrowBlueInput}  ${isVisibleCalendar ? styles.iconRotateInput : ''}`}
            />
            <i className={`${styles.calendarInput}`} />
          </div>
          <div
            className={classNames(styles.filterBtn, 'col-4 col-sm-3 pl-1')}
            style={{ textAlign: 'right' }}
          >
            <div
              onClick={() => setIsVisibleForm(!isVisibleForm)}
              className={`${styles.btn} ${styles.btnWide} ${styles.btnSmall} ${styles.btnBlueOutline} ${styles.mobileFilterBtn}`}
            >
              <i
                className={`${styles.icon} ${styles.iconArrowBlue}  ${!isVisibleForm ? styles.iconRotate : ''}`}
              />
              Фильтр
            </div>
          </div>
        </div>

        {isOpen && (
          <>
            <div className={styles.calendar}>
              <DefinedRange
                className={styles.range}
                onChange={(item) => {
                  setClickCount(2)
                  dispatch(setCalendarRange([item.selection]))
                }}
                inputRanges={[]}
                rangeColors={['#11e651']}
                ranges={calendarRange}
                staticRanges={staticRangesArray}
              />
              <DateRange
                className={styles.dateRange}
                rangeColors={['#0f6dba']}
                locale={ru}
                dateDisplayFormat="dd.MM.yyyy"
                editableDateInputs={true}
                onChange={(item) => {
                  setClickCount((prev) => (prev += 1))
                  dispatch(setCalendarRange([item.selection]))
                }}
                moveRangeOnFirstSelection={false}
                ranges={calendarRange}
                months={2}
              />
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default ReportsCalendar
