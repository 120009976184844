import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { MyKnownError } from '../../types/common'
import axiosInstance from '../../../utils/axiosInstance'
import { URL } from '../../../constants/url'
import { toast } from 'react-toastify'
import { RootState } from '../../store'

// Определение типа для элемента массива contracts
interface Country {
  iso: string
  name: string
}

interface Client {
  country: Country
  id: number
  name: string
  logo: {
    original: string
    lg: string
    md: string
    sm: string
  }
}

export interface Contract {
  id: number
  active: boolean
  amount: string
  client: Client
  currency: string
  date: string // "2024-01-01"
  expired: boolean
  expires_at: string // "2024-12-31"
  number: string
}

// Начальное состояние
interface ContractsState {
  currentContract: Contract | null
  contracts: Contract[] | []
  isLoading: boolean
  error: string | null
  isVisibleDeleteModalContract: boolean
  isVisibleModalContract: boolean
  isPendingContract: boolean
}

const initialState: ContractsState = {
  isVisibleDeleteModalContract: false,
  isVisibleModalContract: false,
  currentContract: null,
  contracts: [],
  isLoading: false,
  error: null,
  isPendingContract: false,
}

export const fetchContracts = createAsyncThunk(
  'contracts/fetchContracts',
  async (_, thunkAPI) => {
    try {
      const response: AxiosResponse = await axiosInstance.get(
        `/${URL.document}/contracts`
      )

      if (response.statusText === 'OK') {
        return response.data
      }
      throw new Error()
    } catch (error) {
      const typedError = error as MyKnownError

      return thunkAPI.rejectWithValue(
        typedError?.response?.data?.message || 'Ошибка при отправке данных'
      )
    }
  }
)

export const fetchDeleteContract = createAsyncThunk(
  'contracts/fetchDeleteContract',
  async (_, thunkAPI) => {
    const {
      contracts: { currentContract },
    } = thunkAPI.getState() as RootState
    try {
      const response = await axiosInstance.delete(
        `/${URL.document}/contracts/${currentContract?.id}`
      )

      if (
        response.statusText === 'OK' ||
        response.statusText === 'Created' ||
        String(response.status) === '204'
      ) {
        return { ...response.data, id: currentContract?.id }
      }
      throw new Error('Не удалось отправить данные')
    } catch (error) {
      console.error('Ошибка при отправке данных:', error)
      return thunkAPI.rejectWithValue('Ошибка при отправке данных')
    }
  }
)

type FetchAddContract = {
  id?: string | number
  isActive: boolean
  date: string
  expiresAt: string
  number: string | number
  clientId: string
  amount?: number | string
  currency: string
}

export const fetchAddContract = createAsyncThunk(
  'contracts/fetchAddContract',
  async (
    {
      id,
      isActive,
      clientId,
      date,
      expiresAt,
      number,
      amount,
      currency,
    }: FetchAddContract,
    thunkAPI
  ) => {
    const formData = new FormData()

    if (currency) formData.append('currency', currency)
    if (amount) formData.append('amount', String(amount))
    formData.append('active', isActive ? '1' : '0')
    formData.append('date', date)
    formData.append('number', String(number))
    formData.append('expires_at', expiresAt)
    formData.append('client_id', clientId)

    if (id) {
      formData.set('_method', 'put')
    }

    try {
      const response = await axiosInstance.post(
        `/${URL.document}/contracts${id ? `/${id}` : ''}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )

      if (response.statusText === 'OK' || response.statusText === 'Created') {
        return response.data
      }
      throw new Error('Не удалось создать контракт')
    } catch (error) {
      const typedError = error as MyKnownError
      return thunkAPI.rejectWithValue(
        typedError?.response?.data?.message || 'Ошибка при отправке данных'
      )
    }
  }
)

const sortByDate = (arr: Contract[]) => {
  return Boolean(arr.length)
    ? arr.sort((a, b) => {
        return new Date(b.date).getTime() - new Date(a.date).getTime()
      })
    : arr
}

const contractsSlice = createSlice({
  name: 'contracts',
  initialState,
  reducers: {
    setCurrentContract: (state, action) => {
      return { ...state, currentContract: action.payload }
    },
    clearContracts: (state) => {
      return { ...state, contracts: [] }
    },
    setIsVisibleModalContract: (state, action: { payload: boolean }) => {
      return {
        ...state,
        isVisibleModalContract: action.payload,
        currentContract: action.payload ? state.currentContract : null,
      }
    },

    setIsVisibleDeleteModalContract: (state, action: { payload: boolean }) => ({
      ...state,
      isVisibleDeleteModalContract: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchContracts.pending, (state) => {
        state.isLoading = true
        state.error = null
        state.isPendingContract = true
      })
      .addCase(
        fetchContracts.fulfilled,
        (state, action: PayloadAction<Contract[]>) => {
          state.isPendingContract = false
          state.contracts = sortByDate(action.payload)
          state.isLoading = false
        }
      )
      .addCase(fetchContracts.rejected, (state, action) => {
        state.isLoading = false
        state.isPendingContract = false
        state.error = action.payload as string
      })
      .addCase(fetchDeleteContract.pending, (state) => {
        return { ...state, isPendingContract: true }
      })
      .addCase(fetchDeleteContract.fulfilled, (state, action) => {
        toast.success('Контракт удален!')

        return {
          ...state,
          isVisibleDeleteModalContract: false,
          isPendingContract: false,
          contracts: state.contracts.filter(
            (item) => item.id !== action.payload.id
          ),
        }
      })
      .addCase(fetchDeleteContract.rejected, (state, action) => {
        toast.error(action.payload as string)

        return { ...state, isPendingContract: false }
      })
      .addCase(fetchAddContract.pending, (state) => {
        return { ...state, isPendingContract: true }
      })
      .addCase(fetchAddContract.fulfilled, (state, action) => {
        toast.success('Контракт добавлен!')

        const isExist = state.contracts.find(
          (contract) => contract.id === action.payload.id
        )

        if (isExist) {
          const sortContracts = state.contracts.map((contract) =>
            contract.id === action.payload.id ? action.payload : contract
          )
          return {
            ...state,
            isVisibleModalContract: false,
            isPendingContract: false,
            contracts: sortByDate(sortContracts),
          }
        } else {
          return {
            ...state,
            isVisibleModalContract: false,
            isPendingContract: false,
            contracts: sortByDate([action.payload, ...state.contracts]),
          }
        }
      })
      .addCase(fetchAddContract.rejected, (state, action) => {
        toast.error(action.payload as string)
        return { ...state, isPendingContract: false }
      })
  },
})

export const {
  clearContracts,
  setIsVisibleModalContract,
  setIsVisibleDeleteModalContract,
  setCurrentContract,
} = contractsSlice.actions

export default contractsSlice.reducer
