import classNames from 'classnames'
import styles from './LoadingPage.module.css'
import Spinner from '../spinner/Spinner'

const LoadingPage = () => (
  <div
    id="page_loading"
    className={classNames(
      styles.pageLoading
      // !isPending ? styles.fadeOut : ''
    )}
  >
    {' '}
    <div className={styles.loadingImg}>
      <span
        style={{
          background: "url('/img/logo/logo.svg') center center no-repeat",
        }}
        className={styles.logoCirc}
      />
      <Spinner />
      <div className={styles.loadingTxt} style={{ textAlign: 'center' }}>
        Loading...
      </div>
    </div>
  </div>
)

export default LoadingPage
