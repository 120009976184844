import { RootState } from '../store'

export const serviceSelector = (state: RootState) => {
  const { service } = state

  const sortedServices = Boolean(service?.services?.length)
    ? [...service.services].sort((a, b) => {
        return new Date(b.date).getTime() - new Date(a.date).getTime()
      })
    : service?.services

  return {
    ...service,
    services: sortedServices,
  }
}
